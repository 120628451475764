export default (translations) => ({
  newPassword: {
    required: true,
    requiredMessage: translations.RequiredMessage,
    minLength: 6,
    message: translations.MinimumLength
  },
  repeatNewPassword: {
    required: true,
    requiredMessage: translations.RequiredMessage,
    minLength: 6,
    message: translations.MinimumLength,
    match: 'newPassword',
    matchMessage: translations.ConfirmationPasswordError
  }
})