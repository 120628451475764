import {
  combineReducers
} from 'redux'

import products from  '../MoominProducts/duck'
import messages from '../MoominMessages/duck'

const reducer = combineReducers({
  products,
  messages
})

export default reducer