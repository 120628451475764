import React, { Component } from 'react'
import './style.scss'

export class ResellerCountry extends Component {
  render() {
    const {
      CountryName,
      Cities
    } = this.props.country

    return (
      <div className="resellerCountry col-xs-12">
        <div className="textContainer col-xs-12">
          <h3>{CountryName}</h3>
          {
            Cities.map((city, i) => {
              return <div key={i}>
                {
                  city.Customers.map((customer, i) => (
                    <div key={i} className="customerRow col-xs-12">
                      <div className="customer">{customer.Name}</div>
                      <div className="city">{city.City}</div>
                    </div>
                  ))
                }
              </div>
            })
          }
        </div>
      </div>
    )
  }
}

export default ResellerCountry
