export default {
  settings: {},
  webTexts: {},
  language: {
    Common: {},
    ErrorTexts: {},
    Menu: {},
    Cart: {},
    Product: {},
    Products: {},
    ProductCard: {},
    StartPage: {},
    Reseller: {},
    Contact: {},
    Search: {},
    Header: {},
    Colors: {},
    Materials: {},
    ProductGroups: {},
    MyPage: {},
    Orders: {},
    CustomerDetails: {},
    Invoices: {},
    DeliveryAddress: {},
    PressRoom: {},
    SetNewPassword: {},
    ResetPassword: {}
  },
  currentLanguage: 'sv',
  showMenu: false,
  currentPage: '',
  showMyPage: false,
  countries: null
}