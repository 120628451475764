export default (translations) => {
  return {
    Name: {
      minlength: 2,
      message: translations.MinimumLength
    },
    Address1: {
      minLength: 2,
      message: translations.MinimumLength
    },
    Address2: {
      minLength: 2,
      message: translations.MinimumLength
    },
    City: {
      minLength: 2,
      message: translations.MinimumLength
    },
    ZipCode: {
      minLength: 5,
      message: translations.MinimumLength
    },
    CountryCode: {
      minLength: 2,
      message: translations.MinimumLength
    },
  }
}