import initialState from './initialState'

const FETCH_PRODUCTS = 'md/features/Pressroom/PressProducts/FETCH_PRODUCTS'
const FETCH_PRODUCTS_SUCCESS = 'md/features/Pressroom/PressProducts/FETCH_PRODUCTS_SUCCESS'
const FETCH_PRODUCTS_FAILED = 'md/features/Pressroom/PressProducts/FETCH_PRODUCTS_FAILED'

export const fetchProducts = () => {
  return {
    types: [FETCH_PRODUCTS, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILED],
    callApi: {
      endpoint: 'api/PressroomArticles',
      method: 'GET'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true
      }
    case FETCH_PRODUCTS_SUCCESS: 
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}