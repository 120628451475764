import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  validateObject,
  validate
} from 'lib/validator'
import {
  updatePassword,
  passwordFormChanged
} from 'features/MyPage/UserProfile/duck'

import validationRules from './validationRules'

import DefaultInput from 'components/common/inputs/DefaultInput'
import Button from 'components/common/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

export class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target

    this.props.dispatch(passwordFormChanged(name, value))
  }

  onClick = (e) => {
    e.preventDefault()
    const {
      lng,
      passwordForm
    } = this.props

    const validations = validationRules(lng.ErrorTexts)
    const validate = validateObject(validations, passwordForm)

    this.setState({
      errors: {
        ...validate.properties
      }
    })

    if (validate.valid) {
      const newPW = passwordForm.NewPassword
      const currentPw = passwordForm.CurrentPassword
      this.props.dispatch(updatePassword(currentPw, newPW))
    }
  }

  validateInput = e => {
    const {
      name,
      value
    } = e.target

    const {
      lng,
      passwordForm
    } = this.props

    const validations = validationRules(lng.ErrorTexts)

    const validation = validate(validations[name], value, passwordForm)
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: validation.error
      }
    })
  }

  render() {
    const {
      errors
    } = this.state
    const {
      lng,
      customerinfo,
      passwordForm,
      loading
    } = this.props

    return (
      <div className="userProfile row">
        <div className="firstGroup col-xs-12 col-sm-6">
          <h3>{lng.MyPage.MyDetails}</h3>
          <DefaultInput name="name" label={lng.CustomerDetails.Name} type="text" value={customerinfo ? customerinfo.Name : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="email" label={lng.CustomerDetails.Email} type="text" value={customerinfo ? customerinfo.Email : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="address1" label={lng.CustomerDetails.Address1} type="text" value={customerinfo ? customerinfo.InvoiceAddress.Address1 : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="address2" label={lng.CustomerDetails.Address2} type="text" value={customerinfo ? customerinfo.InvoiceAddress.Address2 : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="city" label={lng.CustomerDetails.City} type="text" value={customerinfo ? customerinfo.InvoiceAddress.City : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="zipcode" label={lng.CustomerDetails.ZipCode} type="text" value={customerinfo ? customerinfo.InvoiceAddress.ZipCode : ''} onChange={this.onChange} disabled={true} />
          <DefaultInput name="contactperson" label={lng.CustomerDetails.ContactPerson} value={customerinfo ? customerinfo.InvoiceAddress.ContactPerson : ''} type="text" onChange={this.onChange} disabled={true} />
          <DefaultInput name="phone" label={lng.CustomerDetails.Telephone} type="text" value={customerinfo ? customerinfo.Telephone : ''} onChange={this.onChange} disabled={true} />
        </div>
        <div className="secondGroup col-xs-12 col-sm-6">
          <h3>{lng.CustomerDetails.ChangePassword}</h3>
          <div className="pwInput">
            <form onSubmit={this.onClick}>
              <DefaultInput name="CurrentPassword" autocomplete="current-password" label={lng.CustomerDetails.CurrentPassword} type="password" value={passwordForm.CurrentPassword} onChange={this.onChange} onBlur={this.validateInput} error={errors.CurrentPassword} />
              <DefaultInput name="NewPassword" autocomplete="new-password" label={lng.CustomerDetails.NewPassword} type="password" value={passwordForm.NewPassword} onChange={this.onChange} onBlur={this.validateInput} error={errors.NewPassword} />
              <DefaultInput name="RepeatNewPassword" autocomplete="new-password" label={lng.CustomerDetails.ConfirmationPassword} type="password" value={passwordForm.RepeatNewPassword} onChange={this.onChange} onBlur={this.validateInput} error={errors.RepeatNewPassword} />
              <Button type="submit" text={lng.CustomerDetails.ChangePassword} />
            </form>
          </div>
        </div>
        {loading ? <LoadingOverlay /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  customerinfo: state.user.login.user,
  passwordForm: state.mypage.userprofile.passwordForm,
  loading: state.mypage.userprofile.loading
})

export default connect(mapStateToProps)(UserProfile)
