import initialState from './initialState'
import {
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const FETCH_ADDRESSES = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESSES'
const FETCH_ADDRESSES_SUCCESS = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESSES_SUCCESS'
const FETCH_ADDRESSES_FAILED = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESSES_FAILED'

const FETCH_ADDRESS = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESS'
const FETCH_ADDRESS_SUCCESS = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESS_SUCCESS'
const FETCH_ADDRESS_FAILED = 'md/features/MyPage/DeliveryAddresses/FETCH_ADDRESS_FAILED'

const UPDATE_ADDRESS = 'md/features/MyPage/DeliveryAddress/UPDATE_ADDRESS'
const UPDATE_ADDRESS_SUCCESS = 'md/features/MyPage/DeliveryAddress/UPDATE_ADDRESS_SUCCESS'
const UPDATE_ADDRESS_FAILED = 'md/features/MyPage/DeliveryAddress/UPDATE_ADDRESS_FAILED'

const ADD_ADDRESS = 'md/features/MyPage/DeliveryAddress/ADD_ADDRESS'
const ADD_ADDRESS_SUCCESS = 'md/features/MyPage/DeliveryAddress/ADD_ADDRESS_SUCCESS'
const ADD_ADDRESS_FAILED = 'md/features/MyPage/DeliveryAddress/ADD_ADDRESS_FAILED'

const TOGGLE_FORM = 'md/features/MyPage/DeliveryAddresses/TOGGLE_FORM'
const ADDRESS_CHANGE = 'md/features/MyPage/DeliveryAddresses/ADDRESS_CHANGE'

const SET_DEFAULT_ADDRESS = 'md/features/MyPage/DeliveryAddresses/SET_DEFAULT_ADDRESS'
const SET_DEFAULT_ADDRESS_SUCCESS = 'md/features/MyPage/DeliveryAddresses/SET_DEFAULT_ADDRESS_SUCCESS'
const SET_DEFAULT_ADDRESS_FAILED = 'md/features/MyPage/DeliveryAddresses/SET_DEFAULT_ADDRESS_FAILED'

export const fetchAdresses = () => {
  return {
    types: [FETCH_ADDRESSES, FETCH_ADDRESSES_SUCCESS, FETCH_ADDRESSES_FAILED],
    callApi: {
      endpoint: 'api/deliveryaddresses',
      method: 'GET',
    }
  }
}

export const fetchAddress = (addressId) => {
  return {
    types: [FETCH_ADDRESS, FETCH_ADDRESS_SUCCESS, FETCH_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/deliveryaddress/' + addressId,
      method: 'GET',
    }
  }
}

export const updateAddress = (id, payload) => {
  return {
    types: [UPDATE_ADDRESS, UPDATE_ADDRESS_SUCCESS, UPDATE_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/deliveryaddress/' + id,
      method: 'PUT',
      data: payload
    }
  }
}

export const addAddress = (payload) => {
  return {
    types: [ADD_ADDRESS, ADD_ADDRESS_SUCCESS, ADD_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/deliveryaddress/',
      method: 'POST',
      data: payload
    }
  }
}

export const setDefaultAddress = (addressId) => {
  return {
    types: [SET_DEFAULT_ADDRESS, SET_DEFAULT_ADDRESS_SUCCESS, SET_DEFAULT_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/DeliveryAddress/DefaultAddress/' + addressId,
      method: 'PUT',
    }
  }
}

export const toggleForm = (id) => {
  return {
    type: TOGGLE_FORM,
    id
  }
}

export const addressChange = (name, value) => {
  return {
    type: ADDRESS_CHANGE,
    name,
    value
  }
}

export default function reducer(state = initialState, action) {

  switch (action.type) {
    case COMMON_LOGGEDOUT:
      return {
        ...state,
        deliveryAddresses: null,
        deliveryAddress: null
      }
    case FETCH_ADDRESSES:
      return {
        ...state,
        loading: true,
        toggleReload: false
      }
    case FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAddresses: action.data
      }
    case FETCH_ADDRESSES_FAILED:
      return {
        ...state,
        loading: false
      }
    case FETCH_ADDRESS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAddress: action.data
      }
    case FETCH_ADDRESS_FAILED:
      return {
        ...state,
        loading: false
      }
    case TOGGLE_FORM:
      return {
        ...state,
        showForm: !state.showForm,
        addressId: action.id,
        deliveryAddress: {
          Name: '',
          Address1: '',
          Address2: '',
          ZipCode: '',
          City: '',
          CountryCode: '',
          CountryName: '',
          ContactPerson: '',
          DefaultAddress: false
        }
      }
    case ADDRESS_CHANGE:
      return {
        ...state,
        deliveryAddress: {
          ...state.deliveryAddress,
          [action.name]: action.value
        }
      }
    case UPDATE_ADDRESS:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        showForm: false,
        toggleReload: true
      }
    case UPDATE_ADDRESS_FAILED:
      return {
        ...state,
        loading: false
      }
    case ADD_ADDRESS:
      return {
        ...state,
        loading: true,
      }
    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        showForm: false,
        toggleReload: true
      }
    case ADD_ADDRESS_FAILED:
      return {
        ...state,
        loading: false
      }
    case SET_DEFAULT_ADDRESS:
      return {
        ...state,
        loading: true
      }
    case SET_DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryAddresses: action.data
      }
    case SET_DEFAULT_ADDRESS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}