import initialState from './initialState'
import {
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const FETCH_DUE_INVOICES = 'md/features/DueInvoices/FETCH_DUE_INVOICES'
const FETCH_DUE_INVOICES_SUCCESS = 'md/features/DueInvoices/FETCH_DUE_INVOICES_SUCCESS'
const FETCH_DUE_INVOICES_FAILED = 'md/features/DueInvoices/FETCH_DUE_INVOICES_FAILED'

const TOGGLE_WINDOW = 'md/features/DueInvoices/TOGGLE_WINDOW'

export const fetchDueInvoices = () => {
  return {
    types: [FETCH_DUE_INVOICES, FETCH_DUE_INVOICES_SUCCESS, FETCH_DUE_INVOICES_FAILED],
    callApi: {
      endpoint: 'api/DueInvoices',
      method: 'GET'
    }
  }
}

export const toggleDueInvoices = () => {
  return {
    type: TOGGLE_WINDOW
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
      return {
        ...state,
        invoices: null,
        shown: false
      }
    case FETCH_DUE_INVOICES:
      return {
        ...state,
        loading: true
      }
    case FETCH_DUE_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.data,
        showWindow: action.data.length > 0
      }
    case TOGGLE_WINDOW:
      return {
        ...state,
        showWindow: !state.showWindow
      }
    default:
      return {
        ...state
      }
  }
}