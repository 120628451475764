import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchArticle } from './duck'
import './style.scss'
import ArticleInfo from './ArticleInfo'
import LoadingSpinner from 'components/common/LoadingSpinner'

export class ArticleFeature extends Component {
  componentWillReceiveProps(nextProps) {
    const hasLoggedIn = !this.props.loggedIn && nextProps.loggedIn
    if (hasLoggedIn) {
      const {
        data
      } = this.props

      if (data && data.Id)
        this.props.dispatch(fetchArticle(data.Id))
    }
  }

  componentWillMount = () => {
    const id = Number(this.props.match.params.id)
    if (id)
      this.props.dispatch(fetchArticle(id))
  }

  render() {
    const {
      data,
      lng,
      productgroups,
      loading,
      currentLanguage,
    } = this.props

    return (
      <div className="articleFeature">
        {loading ? <LoadingSpinner /> : null}
        {data && lng && productgroups ? <ArticleInfo
          article={data}
          lng={lng}
          productgroups={productgroups}
          currentLanguage={currentLanguage}
          orderMonthsEN={this.props.settings.OrderMonthEN}
          orderMonthsSV={this.props.settings.OrderMonthSV}
          orderMonthsSeasonEN={this.props.settings.OrderMonthsSeasonEN}
          orderMonthsSeasonSV={this.props.settings.OrderMonthsSeasonSV} /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  productgroups: state.productgroups.items,
  lng: state.app.language,
  data: state.article.data,
  loading: state.article.loading,
  settings: state.app.settings,
  currentLanguage: state.app.currentLanguage,
  loggedIn: state.user.login.loggedIn
})

export default connect(mapStateToProps)(ArticleFeature)
