import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

export default class AutoSize extends PureComponent {
  static propTypes = {
    maxWidth: PropTypes.number.isRequired,
    maxFontSize: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      calculated: false
    }
  }

  componentDidMount() {
    const {
      calculated
    } = this.state

    if(!calculated) {
      const width = this.refs.autosize.getBoundingClientRect().width

      const maxWidth = this.props.maxWidth || 60
      const maxFontSize = this.props.maxFontSize || 14

      const calculation = maxWidth / width
      const calculatedFontSize = calculation * maxFontSize
      const fontSize = calculatedFontSize > maxFontSize ? maxFontSize : calculatedFontSize

      this.setState({
        calculated: true,
        fontSize: fontSize
      })
    }
  }

  render() {
    const style = {
      fontSize: `${this.state.fontSize}px`
    }

    return (
      <div className="autoSize" ref="autosize" style={style}>
        {this.props.children}
      </div>
    )
  }
}
