import {
  combineReducers
} from 'redux'

import orders from 'features/MyPage/Orders/duck'
import userprofile from 'features/MyPage/UserProfile/duck'
import deliveryaddresses from 'features/MyPage/DeliveryAddresses/duck'
import invoices from 'features/MyPage/Invoices/duck'

const reducer = combineReducers({
  orders,
  userprofile,
  deliveryaddresses,
  invoices
})

export default reducer