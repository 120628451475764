import initialState from './initialState'
import {
  processTexts
} from 'redux/processors/webtexts'

const FETCH_SETTINGS = 'md/features/App/FETCH_SETTINGS'
const FETCH_SETTINGS_SUCCESS = 'md/features/App/FETCH_SETTINGS_SUCCESS'
const FETCH_SETTINGS_FAILED = 'md/features/App/FETCH_SETTINGS_FAILED'

const FETCH_LANGUAGE = 'md/features/App/FETCH_LANGUAGE'
const FETCH_LANGUAGE_SUCCESS = 'md/features/App/FETCH_LANGUAGE_SUCCESS'
const FETCH_LANGUAGE_FAILED = 'md/features/App/FETCH_LANGUAGE_FAILED'

const FETCH_COUNTRIES = 'md/features/App/FETCH_COUNTRIES'
const FETCH_COUNTRIES_SUCCESS = 'md/features/App/FETCH_COUNTRIES_SUCCESS'
const FETCH_COUNTRIES_FAILED = 'md/features/App/FETCH_COUNTRIES_FAILED'

const FETCH_WEBTEXTS = 'md/features/App/FETCH_WEBTEXTS'
const FETCH_WEBTEXTS_SUCCESS = 'md/features/App/FETCH_WEBTEXTS_SUCCESS'
const FETCH_WEBTEXTS_FAILED = 'md/features/App/FETCH_WEBTEXTS_FAILED'

const TOGGLE_MENU = 'md/features/App/TOGGLE_MENU'

const UPDATE_HISTORY = 'md/features/App/UPDATE_HISTORY'

const TOGGLE_MYPAGE = 'md/features/App/TOGGLE_MYPAGE'

//Actions
export const fetchSettings = () => {
  return {
    types: [FETCH_SETTINGS, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILED],
    callApi: {
      endpoint: 'api/settings',
      method: 'GET'
    }
  }
}

export const fetchCountries = () => {
  return {
    types: [FETCH_COUNTRIES, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILED],
    callApi: {
      endpoint: 'api/countries',
      method: 'GET',
    }
  }
}

export const fetchLanguage = languageCode => {
  const payload = {
    languageCode
  }

  return {
    types: [FETCH_LANGUAGE, FETCH_LANGUAGE_SUCCESS, FETCH_LANGUAGE_FAILED],
    callApi: {
      endpoint: 'api/language/',
      method: 'GET',
      data: payload
    },
    languageCode
  }
}

export const fetchWebTexts = languageCode => {
  const payload = {
    languageCode
  }
  return {
    types: [FETCH_WEBTEXTS, FETCH_WEBTEXTS_SUCCESS, FETCH_WEBTEXTS_FAILED],
    callApi: {
      endpoint: 'api/webtexts',
      method: 'GET',
      data: payload,
      processData: processTexts
    }
  }
}

export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU
  }
}

export const pageHistory = (page) => {
  return {
    type: UPDATE_HISTORY,
    page
  }
}

export const toggleMyPage = (showmypage) => {
  return {
    type: TOGGLE_MYPAGE,
    showmypage
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data
      }
    case FETCH_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.languageCode
      }
    case FETCH_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.data
      }
    case FETCH_WEBTEXTS_SUCCESS:
      return {
        ...state,
        webTexts: action.data
      }
    case TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu
      }
    case UPDATE_HISTORY:
      return {
        ...state,
        currentPage: action.page,
        showMenu: false
      }
    case TOGGLE_MYPAGE:
      return {
        ...state,
        showMyPage: action.showmypage
      }
    case FETCH_COUNTRIES:
      return {
        ...state,
      }
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data
      }
    case FETCH_COUNTRIES_FAILED:
      return {
        ...state,
        countries: null
      }
    default:
      return state
  }
}