import ResellerForm from 'features/Resellers/ResellerForm'
import React from 'react'
import './style.scss'

const ApplicationPage = () => {
  return (
    <div className="ApplicationPage">
      <ResellerForm />
    </div>
  )
}

export default ApplicationPage