import initialState from './initialState'

import {
  COMMON_LOGGEDIN,
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const SEND_LOGIN = 'md/features/User/LoginWidget/SEND_LOGIN'
const SEND_LOGIN_FAILED = 'md/features/User/LoginWidget/SEND_LOGIN_FAILED'

const CHECK_LOGIN = 'md/features/User/LoginWidget/CHECK_LOGIN'
const CHECK_LOGIN_SUCCESS = 'md/features/User/LoginWidget/CHECK_LOGIN_SUCCESS'
const CHECK_LOGIN_FAILED = 'md/features/User/LoginWidget/CHECK_LOGIN_FAILED'

const LOGOUT = 'md/features/User/LoginWidget/LOGOUT'
const LOGOUT_FAILED = 'md/features/User/LoginWidget/LOGOUT_FAILED'

const FETCH_USER = 'md/features/User/LoginWidget/FETCH_USER'
const FETCH_USER_SUCCESS = 'md/features/User/LoginWidget/FETCH_USER_SUCCESS'
const FETCH_USER_FAILED = 'md/features/User/LoginWidget/FETCH_USER_FAILED'

//Actions
export const login = (username, password) => {
  const postData = {
    username,
    password
  }

  return {
    types: [SEND_LOGIN, COMMON_LOGGEDIN, SEND_LOGIN_FAILED],
    callApi: {
      endpoint: 'api/login',
      method: 'POST',
      data: postData
    }
  }
}

export const checkLogin = () => {
  return {
    types: [CHECK_LOGIN, CHECK_LOGIN_SUCCESS, CHECK_LOGIN_FAILED],
    callApi: {
      endpoint: 'api/login',
      method: 'GET'
    }
  }
}

export const logout = () => {
  return {
    types: [LOGOUT, COMMON_LOGGEDOUT, LOGOUT_FAILED],
    callApi: {
      endpoint: 'api/login',
      method: 'DELETE'
    }
  }
}

export const fetchUser = () => {
  return {
    types: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILED],
    callApi: {
      endpoint: 'api/customer',
      method: 'GET'
    }
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
    case LOGOUT:
      return {
        ...state,
        user: null,
        loggedIn: false,
        requireNewPassword: null,
        authUsed: false
      }
    case COMMON_LOGGEDIN:
      return {
        ...state,
        loading: false,
        user: action.data,
        loggedIn: true
      }
    case SEND_LOGIN:
      return {
        ...state,
        loading: true,
        loggedIn: false,
        error: null,
        invalidLogin: false,
      }
    case SEND_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        user: null,
        loggedIn: false,
        error: action.error,
        invalidLogin: true
      }
    case CHECK_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case CHECK_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        initialCheck: true,
        requireNewPassword: false,
        authUsed: action.data.AuthUsed
      }
    case CHECK_LOGIN_FAILED:
      return {
        ...state,
        user: null,
        loggedIn: false,
        loading: false,
        initialCheck: true
      }
    case FETCH_USER:
      return {
        ...state,
        loading: true
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data
      }
    case FETCH_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        loggedIn: false
      }
    default:
      return state
  }
}