import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DefaultInput from 'components/common/inputs/DefaultInput'
import Button from 'components/common/Button'

import './style.scss'
import RawHTML from 'components/common/RawHTML';

class NewsLetters extends PureComponent {
  render() {
    const {
      lng,
      webTexts
    } = this.props

    return (
      <div className="newsLetters">
        <h3 className="underline">{lng.PressRoom.SubscribeNewsLetterHeader}</h3>
        <div id="mc_embed_signup">
          <form action="https://mittditt.us19.list-manage.com/subscribe/post?u=defa41d7f284574164ba17cab&amp;id=08a2250d23" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
              <DefaultInput name="EMAIL" type="email" label={lng.Common.Email} required={true}></DefaultInput>
              <DefaultInput name="FNAME" type="text" label={lng.Common.FirstName}></DefaultInput>
              <DefaultInput name="LNAME" type="text" label={lng.Common.LastName}></DefaultInput>
              <div id="mergeRow-gdpr" className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group">
                <div className="content__gdpr">
                  <h3>{lng.PressRoom.MarketingPermissions}</h3>
                  <p>{lng.PressRoom.SelectMarketing}:</p>
                  <fieldset className="interestGroups">
                    <label className="checkbox subfield" htmlFor="gdpr_19083"><input type="checkbox" id="gdpr_19083" name="gdpr[19083]" value="Y" className="av-checkbox " /><span>{lng.Common.Email}</span> </label>
                  </fieldset>
                </div>
                <div className="gdpr">
                  <RawHTML content={webTexts.NewsLetterGDPR || ''} />
                </div>
              </div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response"></div>
                <div className="response" id="mce-success-response"></div>
              </div>
              <div className="validation" aria-hidden="true"><input type="text" name="b_defa41d7f284574164ba17cab_08a2250d23" tabIndex="-1" value="" /></div>
              <Button type="submit" name="subscribe" text={lng.PressRoom.Subscribe} />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.app.language,
  webTexts: state.app.webTexts
})

export default connect(mapStateToProps)(NewsLetters)