import React, { Component } from 'react'
import { connect } from 'react-redux'
import CartBody from './CartBody'
import LoadingOverlay from 'components/common/LoadingOverlay'
import DefaultTextArea from 'components/common/inputs/DefaultTextArea'
import DefaultSelect from 'components/common/inputs/DefaultSelect'
import DefaultInput from 'components/common/inputs/DefaultInput'
import DefaultWeekInput from 'components/common/inputs/DefaultWeekInput'


import {
  validate,
  validateObject
} from 'lib/validator'

import {
  isIOS
} from 'lib/utils'

import validationRules from './validationRules'

import {
  updateCartArticle,
  removeCartArticle,
  checkoutChange,
  checkoutDeliveryWeekChange,
  sendOrder,
  fetchWayOfDeliveries,
  checkoutDeliveryDateNumber,
  fetchCart,
  resetOrderComplete
} from '../duck'

import './style.scss'
//import DefaultWeekInput from 'components/common/inputs/DefaultWeekInput'
import Button from 'components/common/Button'
//import moment from 'moment'

export class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      weekInput: null
    }
  }

  onQuantityChange = (rowId, quantity) => {
    this.props.dispatch(updateCartArticle(rowId, quantity))
  }

  onDelete = rowId => {
    this.props.dispatch(removeCartArticle(rowId))
  }

  onCompleteOrder = () => {
    const payload = {
      ...this.props.cart.checkout
    }
    //delete payload.deliveryWeek
    //delete payload.deliveryYear

    const validate = validateObject(validationRules, payload)

    this.setState({
      errors: {
        ...validate.properties
      }
    })

    if (validate.valid) {
      this.props.dispatch(sendOrder(payload))
    }
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target

    this.props.dispatch(checkoutChange(name, value))
    this.validateInput(e)
  }

  onDeliveryWeekChanged = e => {
    const {
      name,
      value,
      valueAsNumber
    } = e.target

    if (valueAsNumber === undefined) {
      this.props.dispatch(checkoutDeliveryWeekChange(name, Number(value)))
    }
    if (valueAsNumber !== undefined) {
      var valueNr = (valueAsNumber / 1000)
      this.props.dispatch(checkoutDeliveryDateNumber(valueNr, value))
    }
  }

  validateInput = e => {
    const {
      name,
      value
    } = e.target

    const validation = validate(validationRules[name], value)
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: validation.error
      }
    })
  }

  orderCompleteActions = () => {
    return dispatch => {
      dispatch(fetchCart())
      dispatch(resetOrderComplete())
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.weekInput === null) {
      const element = document.querySelector('[name="deliveryWeek"]')

      const hasRealWeekInput = element.type === 'week'

      this.setState({
        weekInput: hasRealWeekInput
      })
    }
  }

  componentWillMount() {
    const isIOStest = isIOS()

    if (isIOStest) {
      this.setState({
        weekInput: false
      })
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchWayOfDeliveries())
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.cart.orderComplete && nextProps.cart.orderComplete) {
      const {
        lng
      } = this.props

      alert(`${lng.Cart.ThankYouForOrder} ${nextProps.cart.orderId}`)
      this.props.dispatch(this.orderCompleteActions())
    }
  }

  render() {
    const {
      lng,
      cart
    } = this.props

    return (
      <div className="cart">
        {cart.loading ? <LoadingOverlay /> : null}
        <table className="default">
          <thead>
            <tr>
              <th className="artnr">{lng.Cart.ArtNr}</th>
              <th className="ben">{lng.Cart.Description}</th>
              <th className="antal">{lng.Common.Quantity}</th>
              <th className="pris">{lng.Common.Price}</th>
              <th className="summa">{lng.Common.Amount}</th>
              <th className="radera"></th>
            </tr>
          </thead>
          {
            cart && cart.data ?
              <CartBody
                data={cart.data}
                onDelete={this.onDelete}
                onQuantityChange={this.onQuantityChange}
                lng={lng} />
              : null
          }
        </table>
        {cart.error ? <div className="errorMessage">{lng.Common.Error}</div> : null}
        <div>
          <DefaultTextArea name="orderInfoText" label="Meddelande" value={cart.checkout.orderInfoText} onChange={this.onChange} />
        </div>
        <div className="selectContainer row">
          <div className="col-xs-12 col-sm-3 col-md-2">
            <DefaultSelect
              name="wayOfDeliveryId"
              error={this.state.errors.wayOfDeliveryId}
              arrowStyle={false}
              label={lng.Cart.SelectWayOfDelivery}
              value={cart.checkout.wayOfDeliveryId || ''}
              onChange={this.onChange}>
              <option value="">Välj</option>
              {
                this.props.cart.wayOfDeliveries.map(delivery => {
                  return (<option key={delivery.Id} value={delivery.Id}>{delivery.Name}</option>)
                })
              }
            </DefaultSelect>
          </div>
          {
            this.state.weekInput === false ?
              <div className="col-xs-12 col-sm-3 col-md-2">
                <DefaultWeekInput
                  label={lng.Cart.SelectDeliveryWeek}
                  onChange={this.onDeliveryWeekChanged}
                  week={cart.checkout.deliveryWeek}
                  year={cart.checkout.deliveryYear}
                  yearlabel={lng.Cart.Year}
                  weeklabel={lng.Cart.Week}>
                </DefaultWeekInput>
              </div>
              :
              <div className="col-xs-12 col-sm-3 col-md-2">
                <DefaultInput
                  name="deliveryWeek"
                  type="week"
                  label={lng.Cart.SelectDeliveryWeek}
                  value={cart.checkout.deliveryWeekString || ''}
                  onChange={this.onDeliveryWeekChanged}>
                </DefaultInput>
              </div>
          }
          <div className="col-xs-12 col-sm-3 col-md-2">
            <DefaultSelect
              name="partialDelivery"
              error={this.state.errors.partialDelivery}
              arrowStyle={false}
              label={lng.Cart.SelectPartialDelivery}
              value={cart.checkout.partialDelivery || ''}
              onChange={this.onChange}>
              <option value="">Välj</option>
              <option value="true">Ja</option>
              <option value="false">Nej</option>
            </DefaultSelect>
          </div>
          <div>
            <Button className="light" type="button" text="Skicka beställning" onClick={this.onCompleteOrder} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  cart: state.shoppingcart
})

export default connect(mapStateToProps)(Cart)
