import initialState from './initialState'

const FETCH_PRODUCTS = 'md/features/products/FETCH_PRODUCTS'
const FETCH_PRODUCTS_SUCCESS = 'md/features/products/FETCH_PRODUCTS_SUCCESS'
const FETCH_PRODUCTS_FAILED = 'md/features/products/FETCH_PRODUCTS_FAILED'

const FETCH_SEARCH = 'md/features/products/FETCH_SEARCH'
const FETCH_SEARCH_SUCCESS = 'md/features/products/FETCH_SEARCH_SUCCESS'
const FETCH_SEARCH_FAILED = 'md/features/products/FETCH_SEARCH_FAILED'

const SET_SHOWONLYSTOCKITEMS = 'md/features/products/SET_SHOWONLYSTOCKITEMS'

//Actions
export const setShowOnlyStock = onlyInStock => {
  return {
    type: SET_SHOWONLYSTOCKITEMS,
    onlyInStock
  }
}


export const fetchProducts = (productGroupId, news, seasonNews, onlyInStock) => {
  news = news || false
  const payload = {
    productGroupId,
    news,
    seasonNews,
    onlyInStock
  }

  return {
    types: [FETCH_PRODUCTS, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_FAILED],
    callApi: {
      endpoint: 'api/products',
      method: 'GET',
      data: payload
    },
    productGroupId,
    news,
    seasonNews
  }
}

export const searchProducts = criteria => {
  const payload = {
    searchtext: criteria.searchtext || '',
    productGroupId: criteria.productGroupId || null,
    materialId: criteria.materialId || null,
    colors: criteria.colors || null,
    onlyNews: criteria.onlyNews || false,
    onlyInStock: criteria.onlyStockArticles || false,
    languageCode: criteria.languageCode || 'SV'
  }

  return {
    types: [FETCH_SEARCH, FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILED],
    callApi: {
      endpoint: 'api/search',
      method: 'GET',
      data: payload
    },
    criteria: payload
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
        items: null,
        productGroupId: action.productGroupId,
        news: action.news,
        seasonNews: action.seasonNews,
        search: false,
        searchCriteria: null
      }
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }
    case FETCH_SEARCH:
      return {
        ...state,
        loading: true,
        items: null,
        productGroupId: null,
        search: true,
        searchCriteria: action.criteria,
        news: false,
        seasonNews: false
      }
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        items: action.data,
        loading: false
      }
    case FETCH_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        items: null
      }
    case SET_SHOWONLYSTOCKITEMS:
      return {
        ...state,
        onlyStockArticles: action.onlyInStock
      }
    default:
      return state
  }
}