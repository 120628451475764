import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchAdresses,
  toggleForm,
  setDefaultAddress
} from './duck'

import Addressform from './Addressform'
import Button from 'components/common/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'


export class DeliveryAddresses extends Component {
  toggleForm = (addressId) => {
    this.props.dispatch(toggleForm(addressId))
  }

  toggleCreateForm = () => {
    this.props.dispatch(toggleForm())
  }

  setAsDefault = (addressId) => {
    this.props.dispatch(setDefaultAddress(addressId))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.toggleReload) {
      this.props.dispatch(fetchAdresses())
    }
  }

  componentWillMount() {
    this.props.dispatch(fetchAdresses())
  }

  render() {
    const {
      lng,
      showForm,
      deliveryAddresses,
      loading
    } = this.props

    const addressform = showForm ? <Addressform /> : null

    return (
      <div className="deliveryaddresses">
        <table className="default">
          <thead>
            <tr>
              <th>{lng.CustomerDetails.Name}</th>
              <th>{lng.CustomerDetails.Address1}</th>
              <th>{lng.CustomerDetails.Address2}</th>
              <th>{lng.CustomerDetails.ZipCode}</th>
              <th>{lng.CustomerDetails.City}</th>
              <th>{lng.CustomerDetails.Country}</th>
              <th>{lng.CustomerDetails.ContactPerson}</th>
              <th>{lng.DeliveryAddress.DefaultAddress}</th>
              <th className="button"></th>
            </tr>
          </thead>
          <tbody>
            {
              deliveryAddresses ? deliveryAddresses.map(address => {
                return (
                  <tr key={address.Id}>
                    <td>{address.Name}</td>
                    <td>{address.Address1}</td>
                    <td>{address.Address2}</td>
                    <td>{address.ZipCode}</td>
                    <td>{address.City}</td>
                    <td>{address.CountryCode}</td>
                    <td>{address.ContactPerson}</td>
                    <td>{address.DefaultAddressText}</td>
                    <td className="button">
                      <Button type="button" className="tiny" text={lng.DeliveryAddress.Change} onClick={(() => this.toggleForm(address.Id))} meta={address.Id} />
                      <Button type="button" className="tiny" text={lng.DeliveryAddress.DefaultAddress} disabled={address.DefaultAddress} onClick={(() => this.setAsDefault(address.Id))} meta={address.Id} />
                    </td>
                  </tr>
                )
              }) : null
            }
          </tbody>
        </table>
        {addressform}
        {loading ? <LoadingOverlay /> : null}
        <Button type="button" className="tiny" text={lng.DeliveryAddress.CreateAddress} onClick={(() => this.toggleCreateForm())} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  deliveryAddresses: state.mypage.deliveryaddresses.deliveryAddresses,
  showForm: state.mypage.deliveryaddresses.showForm,
  loading: state.mypage.deliveryaddresses.loading,
  toggleReload: state.mypage.deliveryaddresses.toggleReload
})

export default connect(mapStateToProps)(DeliveryAddresses)
