import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export class ProductCardShortInfo extends PureComponent {
  static propTypes = {
    article: PropTypes.object.isRequired,
    lng: PropTypes.object.isRequired,
    route: PropTypes.string.isRequired
  }

  render() {
    const {
      ArticleText,
      ArticleNumber
    } = this.props.article

    const {
      ProductCard
    } = this.props.lng

    return (
      <div>
        <div className="textContainer">
          <h4 className="productHeader">{ArticleText}</h4>
          <p className="productArtNo">{ProductCard.ArticleNumber}: {ArticleNumber}</p>
        </div>
        <div className="buttonContainer">
          <Link to={this.props.route} className="showInfo">{ProductCard.AdditionalInformation}</Link>
        </div>
      </div>
    )
  }
}

export default ProductCardShortInfo
