import React, { PureComponent } from 'react'
import classNames from 'classnames'

export class SubGroups extends PureComponent {
  onClick = productGroupId => {
    this.props.onClick(productGroupId)
  }

  onClickShowAll = () => {
    this.props.onClick(this.props.productGroupId)
  }

  render() {
    const {
      groups,
      activeGroup,
      isNews,
      lng,
      showAll
    } = this.props

    return (
      <ul className="subGroups">
        <li onClick={this.onClickShowAll} >
          <span className={classNames({ active: showAll })}>{lng.Products.ShowAll}</span>
        </li>

        {
          groups.map(item => (
            <li key={item.ProductGroupId} onClick={() => this.onClick(item.ProductGroupId)} >
              <span className={classNames({ active: item.ProductGroupId === activeGroup && !isNews })}>{lng.ProductGroups[item.ProductGroupId] || item.Name}</span>
            </li>
          ))
        }
      </ul>
    )
  }
}

export default SubGroups
