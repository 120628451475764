import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import './style.scss'
import flagSV from 'images/flag_sv.png'
import flagEN from 'images/flag_en.png'
import classNames from 'classnames'

import {
  fetchLanguage,
  fetchWebTexts,
  toggleMenu,
  pageHistory,
  toggleMyPage
} from 'features/App/duck'

import {
  testLocalstorage,
  checkPathName,
  checkWebshopPathName
} from 'lib/utils'

export class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: '',
      items: []
    }
  }

  generateItems = (lng) => {
    this.setState({
      items: [
        {
          link: '',
          text: lng.Menu.Intro || '',
        },
        {
          link: 'products',
          text: lng.Menu.WebShop || '',
        },
        {
          link: 'kontakt',
          text: lng.Menu.Contact || '',
        },
        {
          link: 'application',
          text: lng.Reseller.Application || '',
        }
      ]
    })
  }

  onChangeLanguage = languageCode => {
    if (testLocalstorage()) {
      localStorage.setItem('language', languageCode)
    }

    this.props.dispatch(this.changeLanguageActions(languageCode))
  }

  changeLanguageActions = languageCode => {
    return dispatch => {
      dispatch(fetchLanguage(languageCode))
      dispatch(fetchWebTexts(languageCode))
    }
  }

  componentDidMount() {
    this.generateItems(this.props.lng)
  }

  componentWillReceiveProps(nextProps) {
    this.generateItems(nextProps.lng)
  }

  componentDidUpdate() {
    let checkedPath = checkPathName(this.props.location.pathname)
    this.props.dispatch(toggleMyPage(checkedPath))
  }

  toggleMenu = () => {
    this.props.dispatch(toggleMenu())
  }

  onNavigate = (pageName) => {
    this.props.dispatch(pageHistory(pageName))
  }
  render() {
    // check if current page starts with link from this.props.history.location.pathname
    const pathname = this.props.location.pathname
    const currentPage = pathname.split('/')[1]

    let isWebshop = checkWebshopPathName(currentPage)

    const links = this.state.items.map(item => (
      <li className={classNames({ active: item.link === 'products' ? isWebshop : item.link === currentPage })} key={item.link}> <Link to={'/' + item.link}>{item.text}</Link></li>
    ))

    return (
      <div className="topmenu">
        <nav className="menu">
          <div className="languages">
            <div onClick={() => this.onChangeLanguage('sv')}><img src={flagSV} alt="Svenska" /></div>
            <div onClick={() => this.onChangeLanguage('en')}><img src={flagEN} alt="Engelska" /></div>
          </div>
          <div className={classNames('containerLinks', { hidden: !this.props.showMenu })}>
            <ul className="links">
              {links}
            </ul>
          </div>
          <div className="hamburger" onClick={this.toggleMenu}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </nav>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  showMenu: state.app.showMenu,
  currentPage: state.app.currentPage
})

export default withRouter(connect(mapStateToProps)(Navigation))
