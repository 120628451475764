import {
  combineReducers
} from 'redux'

import headerimage from '../HeaderImage/duck'
import messages from '../pages/PressMessages/duck'
import message from '../pages/PressMessage/duck'
import moomin from '../pages/Moomin/duck'
import gallery from '../pages/PressGallery/duck'
import saidaboutus from '../pages/SaidAboutUs/duck'
import pressproduct from '../pages/PressProduct/duck'

const reducer = combineReducers({
  headerimage,
  messages,
  message,
  moomin,
  gallery,
  saidaboutus,
  pressproduct
})

export default reducer