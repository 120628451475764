import UserMenu from 'features/MyPage/UserMenu'
import ProductGroups from 'features/ProductGroups'
import React from 'react'
import { useSelector } from 'react-redux'
import './style.scss'

const SubNavigation = () => {
  const login = useSelector(state => state.user.login)
  const showMyPage = useSelector(state => state.app.showMyPage)

  return (
    <div className='SubNavigation'>
      {
        showMyPage && login.loggedIn ?
          <UserMenu />
          :
          <ProductGroups />
      }
    </div>
  )
}

export default SubNavigation