import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './style.scss'

export class RequireLoginPage extends Component {
  render() {
    const {
      lng,
      location
    } = this.props

    let from = location.state && location.state.from ? location.state.from.pathname : null
    if (!from) {
      from = '/products'
    }

    return (
      this.props.isLoggedIn ?
        <Redirect to={{ pathname: from }} />
        :
        <div className="requireLoginPage">
          {lng.Products.RequireLogin}
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    lng: state.app.language,
    isLoggedIn: state.user.login.loggedIn
  }
}

export default connect(mapStateToProps)(RequireLoginPage)