import initialState from './initialState'

const FETCH_REQUIRE_NEW_PASSWORD = 'md/features/User/SetNewPassword/FETCH_REQUIRE_NEW_PASSWORD'
const FETCH_REQUIRE_NEW_PASSWORD_SUCCESS = 'md/features/User/SetNewPassword/FETCH_REQUIRE_NEW_PASSWORD_SUCCESS'
const FETCH_REQUIRE_NEW_PASSWORD_FAILED = 'md/features/User/SetNewPassword/FETCH_REQUIRE_NEW_PASSWORD_FAILED'

const UPDATE_PASSWORD = 'md/features/User/SetNewPassword/UPDATE_PASSWORD'
const UPDATE_PASSWORD_SUCCESS = 'md/features/User/SetNewPassword/UPDATE_PASSWORD_SUCCESS'
const UPDATE_PASSWORD_FAILED = 'md/features/User/SetNewPassword/UPDATE_PASSWORD_FAILED'

export const fetchRequireNewPasswordCheck = () => {
  return {
    types: [FETCH_REQUIRE_NEW_PASSWORD, FETCH_REQUIRE_NEW_PASSWORD_SUCCESS, FETCH_REQUIRE_NEW_PASSWORD_FAILED],
    callApi: {
      endpoint: 'api/CustomerPasswordCheck',
      method: 'GET'
    }
  }
}

export const updatePassword = (newPassword) => {
  const payload = {
    newPassword
  }

  return {
    types: [UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILED],
    callApi: {
      endpoint: 'api/CustomerPasswordCheck',
      method: 'POST',
      data: payload
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUIRE_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        requireNewPassword: action.data,
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case UPDATE_PASSWORD_SUCCESS: 
      return {
        ...state,
        loading: false,
        requireNewPassword: false
      }
    case UPDATE_PASSWORD_FAILED: 
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state
  }
}