import React from 'react'
import './style.scss'

const LoadingSpinner = () => (
  <div className="loadingSpinner">
    <div className="rect1"></div>
    <div className="rect2"></div>
    <div className="rect3"></div>
    <div className="rect4"></div>
    <div className="rect5"></div>
  </div>
)

export default LoadingSpinner