export default {
  loading: false,
  error: false,
  data: null,
  orderComplete: false,
  orderId: null,
  checkout: {
    orderInfoText: '',
    wayOfDeliveryId: null,
    deliveryWeek: null,
    deliveryWeekNumber: null,
    partialDelivery: null,
    deliveryYear: null,
    deliveryWeekString: null
  },
  wayOfDeliveries: []
}