import initialState from './initialState'

const FETCH_PRESSARTICLE = 'md/features/Pressroom/PressProduct/FETCH_PRESSARTICLE'
const FETCH_PRESSARTICLE_SUCCESS = 'md/features/Pressroom/PressProduct/FETCH_PRESSARTICLE_SUCCESS'
const FETCH_PRESSARTICLE_FAILED = 'md/features/Pressroom/PressProduct/FETCH_PRESSARTICLE_FAILED'

export const fetchpressarticle = (id, languagecode) => {
  const payload = {
    id: id,
    languageCode: languagecode
  }
  return {
    types: [FETCH_PRESSARTICLE, FETCH_PRESSARTICLE_SUCCESS, FETCH_PRESSARTICLE_FAILED],
    callApi: {
      endpoint: 'api/pressroomarticle',
      method: 'GET',
      data: payload
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRESSARTICLE:
      return {
        ...state,
        loading: true,
        item: null
      }
    case FETCH_PRESSARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.data
      }
    case FETCH_PRESSARTICLE_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
