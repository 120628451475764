import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export class DefaultTextArea extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    col: PropTypes.number,
    rows: PropTypes.number,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func
  }

  onBlur = e => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      label,
      name,
      placeholder,
      value,
      error,
      disabled,
      maxLength,
      col,
      rows,
      required,
      onChange
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    return (
      <div className="defaultTextArea">
        {labelElement}
        <textarea
          maxLength={maxLength}
          col={col}
          rows={rows}
          name={name}
          className={classNames({ error: error })}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={this.onBlur}
          required={required} />
        {errorElement}
      </div>
    )
  }
}

export default (DefaultTextArea)
