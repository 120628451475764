import initialState from './initialState'

const FETCH_PRODUCTGROUPS = 'md/features/ProductGroups/FETCH_PRODUCTGROUPS'
const FETCH_PRODUCTGROUPS_SUCCESS = 'md/features/ProductGroups/FETCH_PRODUCTGROUPS_SUCCESS'
const FETCH_PRODUCTGROUPS_FAILED = 'md/features/ProductGroups/FETCH_PRODUCTGROUPS_FAILED'

const UPDATE_PRODUCT_HISTORY = 'md/features/ProductGroups/UPDATE_PRODUCT_HISTORY'
const UPDATE_PARENT_HISTORY = 'md/features/ProductGroups/UPDATE_PARENT_HISTORY'



//Actions
export const fetchProductGroups = () => {
  return {
    types: [FETCH_PRODUCTGROUPS, FETCH_PRODUCTGROUPS_SUCCESS, FETCH_PRODUCTGROUPS_FAILED],
    callApi: {
      endpoint: 'api/ProductGroups',
      method: 'GET'
    }
  }
}

export const productgroupHistory = (productgroup, parentgroup) => {
  return {
    type: UPDATE_PRODUCT_HISTORY,
    productgroup,
    parentgroup
  }
}

export const parentgroupHistory = (parentgroup) => {
  return {
    type: UPDATE_PARENT_HISTORY,
    parentgroup
  }
}



//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTGROUPS:
      return {
        ...state,
        loading: true
      }
    case FETCH_PRODUCTGROUPS_SUCCESS:
      return {
        ...state,
        items: action.data,
        loading: false
      }
    case FETCH_PRODUCTGROUPS_FAILED:
      return {
        ...state,
        items: null,
        loading: false
      }
    case UPDATE_PRODUCT_HISTORY:
      return {
        ...state,
        currentProductgroup: action.productgroup,
        currentParentgroup: action.parentgroup
      }
    case UPDATE_PARENT_HISTORY:
      return {
        ...state,
        currentProductgroup: '',
        currentParentgroup: action.parentgroup
      }
    default:
      return state
  }
}