import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fetchImages } from './duck'

import './style.scss'

export class HeaderImage extends PureComponent {
  componentWillMount() {
    const {
      images,
      loading
    } = this.props

    if (!loading && !images)
      this.props.dispatch(fetchImages())
  }

  render() {
    const imageUrl = `${process.env.REACT_APP_API_URL}/api/WebImage/`
    const {
      images
    } = this.props

    return (
      <div className="headerImage">
        {images ? <img src={imageUrl+images[0].Id} alt="Insperation Pressrum" /> : null}
      </div>
    )
  }
}


const mapStateToProps = state => ({
  images: state.pressroom.headerimage.images,
  loading: state.pressroom.headerimage.loading
})
export default connect(mapStateToProps)(HeaderImage)