import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import MoominLogo from 'images/moomin_logo.gif'

import './style.scss'

class PressNavigation extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props)
    this.state = {
      pages: []
    }
  }

  generateNavigation = (lng) => {
    const pages = [
      {
        name: lng.PressRoom.Pressmessages,
        section: 'press'
      },
      {
        name: lng.PressRoom.SaidAboutUs,
        section: 'aboutus'
      },
      {
        name: lng.PressRoom.Gallery,
        section: 'gallery'
      },
      {
        name: lng.PressRoom.NewsLetter,
        section: 'newsletters'
      },
      {
        name: 'Moomin',
        section: 'moomin',
        default: true,
        image: MoominLogo
      }
    ]

    this.setState({
      pages: pages
    })
  }

  onClickSection = (section) => {
    const url = '/pressroom/' + section
    this.context.router.history.push(url)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.lng !== this.props.lng) {
      this.generateNavigation(nextProps.lng)
    }
  }

  componentWillMount() {
    this.generateNavigation(this.props.lng)
  }

  render() {
    const {
      section
    } = this.props

    return (
      <div className="pressNavigation">
        <ul>
          {
            this.state.pages.map(page => (
              <li key={page.section}
                onClick={() => this.onClickSection(page.section)}
                className={classnames({ image: page.image, active: page.section === section || (!section && page.default) })}>
                {page.image ? <img src={page.image} alt={page.name} /> : page.name}
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.app.language
})

export default connect(mapStateToProps)(PressNavigation)