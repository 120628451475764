import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  fetchOrders,
  fetchOrderRows
} from 'features/MyPage/Orders/duck'

import LoadingOverlay from 'components/common/LoadingOverlay'
import OrdersBody from './OrderBody'
import Pagination from 'components/common/Pagination'

import './style.scss'

export class Orders extends Component {
  showOrderRows = (orderId) => {
    this.props.dispatch(fetchOrderRows(orderId))
  }

  onChangePage = page => {
    this.props.dispatch(fetchOrders(page))
  }

  componentWillMount() {
    const {
      loading
    } = this.props

    if (!loading) {
      this.props.dispatch(fetchOrders(1))
    }
  }

  render() {
    const {
      loading,
      loadingRows,
      orderdata,
      lng,
      currentPage,
      take
    } = this.props

    const pagination = <Pagination onChange={this.onChangePage} currentItem={currentPage} take={take} totalCount={orderdata ? orderdata.TotalCount : 0} />

    return (
      <div className="orders">
        {pagination}
        <table className="default subrows">
          <thead>
            <tr>
              <th>{lng.Common.OrderNumber}</th>
              <th>{lng.Orders.OrderDate}</th>
              <th>{lng.Orders.Status}</th>
              <th>{lng.Orders.WayOfDelivery}</th>
              <th className="right">{lng.Orders.Amount}</th>
              <th className="right">{lng.Common.Vat}</th>
              <th className="right">{lng.Common.TotalAmount}</th>
              <th className="button"></th>
            </tr>
          </thead>
          <OrdersBody onShowRows={this.showOrderRows} />
        </table>
        {pagination}
        {loading || loadingRows ? <LoadingOverlay /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loadingRows: state.mypage.orders.loadingRows,
  loading: state.mypage.orders.loading,
  orderdata: state.mypage.orders.data,
  currentPage: state.mypage.orders.currentPage,
  take: state.mypage.orders.take,
  lng: state.app.language
})

export default connect(mapStateToProps)(Orders)
