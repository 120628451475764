import React from 'react'
import MittDittLogo from 'images/logo_text.png'
import './style.scss'

const HeaderBasic = () => {
  return (
    <header className='HeaderBasic'>
      <img src={MittDittLogo} alt="Mitt & Ditt" />
    </header>
  )
}

export default HeaderBasic