import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

class GallerySlider extends PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    startItem: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      currentItem: null,
      currentText: null
    }
  }

  onClickOutside = (e) => {
    if (e.target.classList.contains('gallerySlider'))
      this.props.onClose()
  }

  getCurrentIndex = () => {
    return this.props.items.findIndex(item => item.Id === this.state.currentItem)
  }

  onClickBack = () => {
    this.changeItem(this.getCurrentIndex() - 1)
  }

  onClickForward = () => {
    this.changeItem(this.getCurrentIndex() + 1)
  }

  changeItem = (newIndex) => {
    if (newIndex >= this.props.items.length)
      newIndex = 0
    else if (newIndex < 0)
      newIndex = this.props.items.length - 1

    const newItem = this.props.items[newIndex]
    this.setState({
      currentItem: newItem.Id,
      currentText: newItem.Text
    })
  }

  componentWillMount() {
    const startItem = this.props.items.find(item => item.Id === this.props.startItem)

    this.setState({
      currentItem: startItem.Id,
      currentText: startItem.Text
    })
  }

  render() {
    const imageUrl = `${process.env.REACT_APP_API_URL}/api/WebImage/${this.state.currentItem}`

    return (
      <div className="gallerySlider" onClick={this.onClickOutside}>
        <div>
          <div className="arrow back" onClick={this.onClickBack}>
            <i className="arrow left" />
          </div>
          <img src={imageUrl} alt="gallery" />
          <div className="arrow forward" onClick={this.onClickForward}>
            <i className="arrow right" />
          </div>
          <div className={classNames('textArea', { show: this.state.currentText })}>
            {this.state.currentText}
          </div>
        </div>
      </div>
    )
  }
}

export default GallerySlider