import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  validate,
  validateObject
} from 'lib/validator'

import {
  updatePassword
} from './duck'

import validationRules from './validationRules'

import FloatingWindow from 'components/containers/FloatingWindow'
import DefaultInput from 'components/common/inputs/DefaultInput'
import Button from 'components/common/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

class SetNewPassword extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      newPassword: '',
      repeatNewPassword: '',
      errors: {},
      validationRules: null
    }
  }

  getValidationProperites = () => ({
    newPassword: this.state.newPassword,
    repeatNewPassword: this.state.repeatNewPassword
  })

  validateProperty = (name, value) => {
    return validate(this.state.validationRules[name], value, this.getValidationProperites())
  }

  onSubmit = (e) => {
    e.preventDefault()

    const validation = validateObject(this.state.validationRules, this.getValidationProperites())

    this.setState({
      errors: {
        ...validation.properties
      }
    })

    if (validation.valid) {
      this.props.dispatch(updatePassword(this.state.newPassword))
    }
  }

  validateInput = e => {
    const {
      name,
      value
    } = e.target

    const validation = this.validateProperty(name, value)

    this.setState({
      errors: {
        ...this.state.errors,
        [name]: validation.error
      }
    })
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target

    let setState = {
      ...this.state,
      [name]: value
    }

    if (this.state.errors[name] !== undefined && this.state.errors[name].length) {
      const validation = this.validateProperty(name, value)
      setState = {
        ...setState,
        errors: {
          ...setState.errors,
          [name]: validation.error
        }
      }
    }

    this.setState(setState)
  }

  componentWillMount() {
    const {
      lng
    } = this.props

    this.setState({
      validationRules: validationRules(lng.ErrorTexts)
    })
  }

  render() {
    const {
      lng,
      loading
    } = this.props

    const {
      newPassword,
      repeatNewPassword,
      errors
    } = this.state

    return (
      <FloatingWindow title={lng.CustomerDetails.ChangePassword} draggable={false} showHeader={true} closeOutSide={false}>
        <div className="setNewPassword">
          <div>
            {lng.SetNewPassword.TemporaryPasswordText}.
          </div>
          <form onSubmit={this.onSubmit}>
            <DefaultInput name="newPassword" autocomplete="new-password" label={lng.CustomerDetails.NewPassword} type="password" value={newPassword} onChange={this.onChange} onBlur={this.validateInput} error={errors.newPassword} />
            <DefaultInput name="repeatNewPassword" autocomplete="new-password" label={lng.CustomerDetails.ConfirmationPassword} type="password" value={repeatNewPassword} onChange={this.onChange} onBlur={this.validateInput} error={errors.repeatNewPassword} />
            <Button type="submit" text={lng.CustomerDetails.ChangePassword} />
          </form>
          {loading ? <LoadingOverlay /> : null}
        </div>
      </FloatingWindow>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.user.setnewpassword.loading
})

export default connect(mapStateToProps)(SetNewPassword)