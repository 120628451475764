import React, { Component } from 'react'
import { connect } from 'react-redux'
import MDLogo from 'images/logo_sign_transparent.png'
import DefaultInput from 'components/common/inputs/DefaultInput'
import DefaultTextArea from 'components/common/inputs/DefaultTextArea'
import validationRules from '../validationRules'
import {
  validateObject,
  validate
} from 'lib/validator'
import {
  sendResellerApplication,
  resetState,
  toggleForm
} from '../duck'
import './style.scss'

export class ResellerForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      company: '',
      email: '',
      identity: '',
      address: '',
      zipCodeAndCity: '',
      telephone: '',
      webSite: '',
      message: '',
      resellerErrors: {}
    }
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target

    const validation = validate(validationRules[name], value)

    this.setState({
      [name]: value,
      resellerErrors: {
        ...this.state.resellerErrors,
        [name]: validation.error
      }
    })
  }

  onBlur = (e) => {
    const {
      name,
      value
    } = e.target

    const validation = validate(validationRules[name], value)

    this.setState({
      [name]: value,
      resellerErrors: {
        ...this.state.resellerErrors,
        [name]: validation.error
      }
    })
  }


  onSubmit = (e) => {
    e.preventDefault()
    const validate = validateObject(validationRules, this.state)

    let payload = {
      ...this.state
    }
    delete payload.resellerErrors

    if (validate.valid) {
      this.props.dispatch(sendResellerApplication(payload))
    }
  }

  toggleForm = () => {
    this.props.dispatch(toggleForm())
  }

  componentWillMount() {
    this.props.dispatch(resetState())
  }

  render() {
    const {
      name,
      company,
      email,
      identity,
      address,
      zipCodeAndCity,
      telephone,
      webSite,
      message
    } = this.state

    const {
      applicationSent,
    } = this.props

    return (
      <div>
        {applicationSent ? <div>{this.props.lng.Reseller.ThankYouForAdmission}</div> :

          <div className="resellerForm row">
            <div className="logoContainer col-xs-12 center-xs">
              <img className="col-xs-6" src={MDLogo} alt="logo" />
              <h3 className="col-xs-6">{this.props.lng.StartPage.Reseller}</h3>
            </div>
            <h1 className="col-xs-12 center-xs">{this.props.lng.StartPage.ResellerHeader}</h1>
            <div className="col-xs-12 center-xs">
              <form onSubmit={this.onSubmit}>
                <div className="form row center-sm col-xs-12">
                  <div className="col-sm-6 col-xs-12">
                    <DefaultInput name="name" type="text" label={this.props.lng.Reseller.Name + ' (' + this.props.lng.Common.MustInclude + ')'} value={name} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.name}></DefaultInput>
                    <DefaultInput name="company" type="text" label={this.props.lng.Reseller.CompanyName + ' (' + this.props.lng.Common.MustInclude + ')'} value={company} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.companyName}></DefaultInput>
                    <DefaultInput name="email" type="text" label={this.props.lng.Reseller.EMail + ' (' + this.props.lng.Common.MustInclude + ')'} value={email} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.email}></DefaultInput>
                    <DefaultInput name="identity" type="text" label={this.props.lng.Reseller.OrganisationNumber + ' (' + this.props.lng.Common.MustInclude + ')'} value={identity} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.organisationNumber}></DefaultInput>
                    <DefaultInput name="address" type="text" label={this.props.lng.Reseller.Address + ' (' + this.props.lng.Common.MustInclude + ')'} value={address} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.address}></DefaultInput>
                    <DefaultInput name="zipCodeAndCity" type="text" label={this.props.lng.Reseller.PostalCodeAndCity + ' (' + this.props.lng.Common.MustInclude + ')'} value={zipCodeAndCity} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.postalCodeAndCity}></DefaultInput>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    <DefaultInput name="telephone" type="text" label={this.props.lng.Reseller.PhoneNumber + ' (' + this.props.lng.Common.MustInclude + ')'} value={telephone} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.phoneNumber}></DefaultInput>
                    <DefaultInput name="webSite" type="text" label={this.props.lng.Reseller.WebSite} value={webSite} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.webSite}></DefaultInput>
                    <DefaultTextArea name="message" rows={7} type="text" label={this.props.lng.Reseller.Message} value={message} onChange={this.onChange} onBlur={this.onBlur} error={this.state.resellerErrors.message}></DefaultTextArea>
                  </div>
                </div>
                <button className="submitButton" type="submit">{this.props.lng.Reseller.SendApplication}</button>
              </form>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  applicationSent: state.resellers.applicationSent,
  errors: state.resellers.resellerErrors,
})

export default connect(mapStateToProps)(ResellerForm)
