import Terms from 'features/Terms'
import React from 'react'

const TermsPage = () => {
  return (
    <div>
      <Terms />
    </div>
  )
}

export default TermsPage