import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCategoryImages
} from './duck'

import './style.scss'
import ImageGridSlider from 'components/common/ImageGridSlider/ImageGridSlider'
import RawHTML from 'components/common/RawHTML'

const Intro = () => {
  const dispatch = useDispatch()
  const intro = useSelector(state => state.intro)
  const webTexts = useSelector(state => state.app.webTexts)

  const imageUrl = `${process.env.REACT_APP_API_URL}/api/WebImage/`

  React.useEffect(() => {
    dispatch(fetchCategoryImages(1))
  }, [dispatch])

  return (
    <div className="Intro row">
      <div className="col-xs-12">
        <div className="slider col-xs-12 center-xs">
          <ImageGridSlider images={intro.images} endpoint={imageUrl} />
        </div>
      </div>
      <div>
        <RawHTML className="introText" nl2br={false} content={webTexts.Intro || ''}></RawHTML>
      </div>
    </div>
  )
}

export default Intro