import React, { Component } from 'react'
import './style.scss'

export class NotFoundPage extends Component {
  render() {
    return (
      <div className="notFoundPage">
        Sidan du försöker hitta finns inte...
      </div>
    )
  }
}

export default NotFoundPage
