import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noPicture from 'images/nopic.jpg'
import { urlName } from 'lib/utils'
import './style.scss'

import ProductCardShortInfo from './ProductCardShortInfo'
import ProductCardTallInfo from './ProductCardTallInfo'
import AutoSize from 'components/common/AutoSize'

export class ProductCard extends PureComponent {
  static propTypes = {
    article: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
    lng: PropTypes.object.isRequired,
    showTall: PropTypes.bool.isRequired,
    news: PropTypes.bool,
    seasonNews: PropTypes.bool,
    orderMonths: PropTypes.string,
    orderMonthsSeason: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired,
    newsText: PropTypes.string,
    seasonNewsText: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      quantity: null,
      addQuantities: [],
      addedToCart: false,
    }
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target

    this.setState({
      [name]: Number(value),
      addedToCart: false
    })
  }

  addToCart = () => {
    this.props.addToCart(this.props.article.Id, this.state.quantity)
    this.setState({
      addedToCart: true
    })
  }

  //Do not remove!
  //Workaround for iOS that require onClick event on hover elements
  // onClick = () => { }
  onClick = () => {
    const articleRoute = `/article/${this.props.article.Id}/` + urlName(this.props.article.ArticleText)
    this.props.history.push(articleRoute)
  }

  componentWillMount() {
    const {
      QuantityPerPackage
    } = this.props.article

    const options = []
    for (let i = 1; i <= 10; i++) {
      options.push(i * QuantityPerPackage)
    }

    this.setState({
      addQuantities: options,
      quantity: options[0]
    })
  }

  render() {
    const {
      ArticleText,
      ImageId,
      Id,
      RegularPrice,
      Discount,
      News,
      SeasonNews
    } = this.props.article

    const imageUrl = ImageId ? `${process.env.REACT_APP_API_URL}/api/ArticleThumbnail/${ImageId}` : noPicture
    const articleRoute = `/article/${Id}/` + urlName(ArticleText)

    const imageStyle = {
      backgroundImage: `url(${imageUrl})`
    }

    let cardNewsText = null
    if (SeasonNews)
      cardNewsText = this.props.seasonNewsText
    else if (News)
      cardNewsText = this.props.newsText

    return (
      <div className={classNames('productCard', { tall: this.props.showTall })} >
        {cardNewsText ? <div className="new"><AutoSize maxWidth={70} maxFontSize={14}>{cardNewsText}</AutoSize></div> : null}
        {RegularPrice ? <div className="sale">{Discount} %</div> : null}
        {this.state.addedToCart ? <div className="kopInfo">{this.state.quantity}st har lagts i varukorgen.</div> : null}
        <div onClick={this.onClick} className="imageContainer" style={imageStyle}></div>
        {
          this.props.showTall ?
            <ProductCardTallInfo
              article={this.props.article}
              lng={this.props.lng}
              addToCart={this.addToCart}
              onChange={this.onChange}
              addQuantities={this.state.addQuantities}
              quantity={this.state.quantity}
              orderMonths={this.props.orderMonths}
              orderMonthsSeason={this.props.orderMonthsSeason}
              currentLanguage={this.props.currentLanguage} />
            :
            <ProductCardShortInfo
              article={this.props.article}
              lng={this.props.lng}
              route={articleRoute} />
        }
        <div className="whiteOverlay"></div>
      </div>
    )
  }
}

export default withRouter(ProductCard)