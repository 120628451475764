export default (translations) => {
  return {
    CurrentPassword: {
      required: true,
      requiredMessage: translations.RequiredMessage
    },
    NewPassword: {
      required: true,
      requiredMessage: translations.RequiredMessage,
      minLength: 6,
      message: translations.MinimumLength
    },
    RepeatNewPassword: {
      required: true,
      requiredMessage: translations.RequiredMessage,
      minLength: 6,
      message: translations.MinimumLength,
      match: 'NewPassword',
      matchMessage: translations.ConfirmationPasswordError
    }
  }
}