import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import {
  hideWidget,
  toggleWidget,
  formChanged,
  clearForm,
} from './duck'
import {
  searchProducts
} from 'features/Products/duck'
import { fetchMaterials } from 'features/Materials/duck'
import { fetchColors } from 'features/Colors/duck'

import Button from 'components/common/Button'
import DefaultInput from 'components/common/inputs/DefaultInput'
import DefaultSelect from 'components/common/inputs/DefaultSelect'
import ColorCheckbox from 'components/common/inputs/ColorCheckbox'

import './style.scss'

export class SearchWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productgroups: null
    }
  }

  toggleWidget = () => {
    this.props.dispatch(toggleWidget())
  }

  generateProductGroups = (items, loggedIn) => {
    let filteredGroups
    if (!loggedIn) {
      filteredGroups = items.filter(group => !group.RequireLogin)
    } else {
      filteredGroups = items
    }

    let sortedGroups = filteredGroups.filter(item => item.ParentId === null)
      .map(group => {
        return {
          ...group,
          subGroups: []
        }
      })

    const subGroups = filteredGroups.filter(item => item.ParentId !== null)
    subGroups.forEach(item => {
      const mainGroup = sortedGroups.find(group => group.ProductGroupId === item.ParentId)
      mainGroup.subGroups.push(item)
    })

    let groups = []
    sortedGroups.forEach(item => {
      groups.push({
        id: item.ProductGroupId,
        name: item.Name
      })

      item.subGroups.forEach(sub => {
        groups.push({
          id: sub.ProductGroupId,
          name: sub.Name,
          parentName: item.Name,
          parentId: item.ProductGroupId
        })
      })
    })

    this.setState({
      productgroups: groups
    })
  }

  onChange = e => {
    const {
      name,
      value,
      checked,
      type
    } = e.target

    const setValue = type === 'checkbox' ? checked : value

    this.props.dispatch(formChanged(name, setValue))
  }

  colorChanged = (id, checked) => {
    const {
      form
    } = this.props

    let colors = [
      ...form.colors
    ]

    const colorIndex = colors.findIndex(colorId => colorId === id)

    if (colorIndex !== -1 && !checked) {
      colors.splice(colorIndex, 1)
    } else if (colorIndex === -1 && checked) {
      colors.push(id)
    } else {
      return
    }

    this.props.dispatch(formChanged('colors', colors))
  }

  searchProducts = (e) => {
    e.preventDefault()

    const {
      form,
      currentLanguage
    } = this.props

    const criteria = {
      searchtext: form.searchtext,
      productGroupId: form.productgroupid,
      materialId: form.material,
      colors: form.colors,
      onlyNews: form.news,
      onlyStockArticles: this.props.showOnlyStockArticles,
      languageCode: currentLanguage
    }

    this.props.dispatch(this.searchAction(criteria))
    this.props.history.push('/products')
  }

  searchAction = (criteria) => {
    return dispatch => {
      dispatch(searchProducts(criteria))
      dispatch(hideWidget())
      dispatch(clearForm())
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.productgroups === null && nextProps.productgroups.items !== null) {
      this.generateProductGroups(nextProps.productgroups.items, nextProps.loggedIn)
    } else if(nextProps.productgroups.items !== null && this.props.loggedIn !== nextProps.loggedIn) {
      this.generateProductGroups(nextProps.productgroups.items, nextProps.loggedIn)
    }
  }

  componentWillMount() {
    const {
      colors,
      materials
    } = this.props

    if (colors.items === null && !colors.loading) {
      this.props.dispatch(fetchColors())
    }

    if (materials.items === null && !materials.loading) {
      this.props.dispatch(fetchMaterials())
    }
  }

  render() {
    const {
      form,
      showWidget,
      lng
    } = this.props

    const toggleText = showWidget ? lng.Search.SearchShow : lng.Search.Search

    return (
      <div className="searchWidget">
        <div className="toggleWrapper">
          <Button type="button" onClick={this.toggleWidget} text={toggleText || ''} />
        </div>
        <form className={classNames({ show: showWidget })} onSubmit={this.searchProducts}>
          <DefaultInput type="search" placeholder={lng.Search.KeyWord} name="searchtext" value={form.searchtext} onChange={this.onChange} />
          <DefaultSelect name="productgroupid"
            onChange={this.onChange}>
            <option defaultValue value="">{lng.Search.Category}</option>
            {
              this.state.productgroups ? this.state.productgroups.map(group => (
                <option key={group.id} value={group.id} selected={Number(form.productgroupid) === group.id}>{group.parentName ? 
                  `${lng.ProductGroups[group.parentId] || group.parentName} > ${lng.ProductGroups[group.id] || group.name}` : lng.ProductGroups[group.id] || group.name}
                </option>
              )) : null
            }
          </DefaultSelect>
          <DefaultSelect name="material"
            onChange={this.onChange}>
            <option defaultValue value="">{lng.Search.Material}</option>
            {
              this.props.materials.items ? this.props.materials.items.map(item => (
                <option key={item.Id} value={item.Id} selected={Number(form.material) === item.Id}>{lng.Materials[item.Id] || item.Name}</option>
              )) : null
            }
          </DefaultSelect>
          <div className="newItemsOnly">
            <label>
              <input type="checkbox" name="news" checked={form.news} onChange={this.onChange} />
              {lng.Search.ShowOnlyNews}
            </label>
          </div>
          <div className="searchByColor">
            {
              this.props.colors.items ? this.props.colors.items.map(item => (
                <ColorCheckbox
                  key={item.Id}
                  hex={item.Hex}
                  id={item.Id}
                  name={lng.Colors[item.Id] || item.Name}
                  checked={form.colors.indexOf(item.Id) !== -1}
                  onClick={this.colorChanged} />
              )) : null
            }
          </div>
          <div className="submitWrapper">
            <Button type="submit" text={lng.Common.Search || ''} />
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  showWidget: state.search.showWidget,
  form: state.search.form,
  lng: state.app.language,
  productgroups: state.productgroups,
  materials: state.materials,
  colors: state.colors,
  currentLanguage: state.app.language.currentLanguage,
  showOnlyStockArticles: state.products.onlyStockArticles,
  loggedIn: state.user.login.loggedIn
})

export default withRouter(connect(mapStateToProps)(SearchWidget))
