import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import {
  fetchProductGroups,
  productgroupHistory,
  parentgroupHistory
} from './duck'
import {
  urlName
} from 'lib/utils'
import SubGroups from './SubGroups'

import './style.scss'

export class ProductGroups extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      items: null
    }
  }

  processGroups = (groups, loggedIn) => {
    let filteredGroups
    if (!loggedIn) {
      filteredGroups = groups.filter(group => !group.RequireLogin)
    } else {
      filteredGroups = groups
    }

    const main = filteredGroups.filter(item => !item.ParentId)

    main.forEach(group => {
      const subGroups = filteredGroups.filter(item => item.ParentId === group.ProductGroupId)
      group.SubGroups = subGroups.length ? subGroups : null
    })

    this.setState({
      items: main
    })
  }

  onClick = id => {
    this.changeProductGroup(id, true)
  }

  onClickSubGroup = id => {
    this.changeProductGroup(id, false)
  }

  changeProductGroup = (id, ignoreChildren) => {
    const productGroup = this.props.items.find(item => item.ProductGroupId === id)
    if (productGroup && (!ignoreChildren || (ignoreChildren && !productGroup.HasChildren))) {
      const {
        ProductGroupId,
        Name
      } = productGroup

      this.props.history.push(`/products/${ProductGroupId}/${urlName(Name)}`)

      this.highlightItem(ProductGroupId)
    }
  }

  onNewsClicked = () => {
    this.props.history.push('/products')
  }

  onSeasonNewsClicked = () => {
    this.props.history.push('/products/seasonnews')
  }


  componentWillReceiveProps(nextProps) {
    if (this.state.items === null && nextProps.items) {
      this.processGroups(nextProps.items, nextProps.loggedIn)
      this.highlightItem(nextProps.items)
    } else if (nextProps.items && this.props.loggedIn !== nextProps.loggedIn) {
      this.processGroups(nextProps.items, nextProps.loggedIn)
    }
  }

  componentWillMount() {
    this.props.dispatch(fetchProductGroups())
  }

  highlightItem = (items) => {
    let loactionArr = this.props.location.pathname.split('/')

    let productGroup
    if (this.state.items === null)
      productGroup = items.find(item => item.ProductGroupId === Number(loactionArr[2]))
    else
      productGroup = this.props.items.find(item => item.ProductGroupId === items)

    if (productGroup) {
      if (productGroup.ParentId === null) ///groupid === parentid
        this.props.dispatch(parentgroupHistory(productGroup.ProductGroupId))
      else
        this.props.dispatch(productgroupHistory(productGroup.ProductGroupId, productGroup.ParentId))
    }
  }

  render() {
    const {
      items
    } = this.state

    const {
      lng,
      settings,
      currentLanguage
    } = this.props

    const seasonNews = settings['SeasonNewsMenu' + currentLanguage.toUpperCase()]

    return (
      <div className="productGroups">
        <ul>
          <li onClick={this.onNewsClicked}><span className={classNames({ active: this.props.isNews })}>{lng.Products.News}</span></li>
          <li onClick={this.onSeasonNewsClicked}><span className={classNames({ active: this.props.isSeasonNews })}>{seasonNews}</span></li>
          {
            items ? items.map(item => (
              <li key={item.ProductGroupId} onClick={() => this.onClick(item.ProductGroupId)} className={classNames({ hasSubGroups: item.SubGroups })}>
                <span className={classNames({ active: item.ProductGroupId === this.props.currentParentgroup && !this.props.isNews && !this.props.isSeasonNews })}>{lng.ProductGroups[item.ProductGroupId] || item.Name}</span>
                {
                  item.SubGroups ?
                    <SubGroups onClick={this.onClickSubGroup}
                      showAll={item.ProductGroupId === this.props.currentParentgroup && this.props.currentProductgroup === ''}
                      productGroupId={item.ProductGroupId}
                      lng={lng} groups={item.SubGroups}
                      activeGroup={this.props.currentProductgroup}
                      isNews={this.props.isNews || this.props.isSeasonNews} />
                    : null
                }
              </li>
            )) : null
          }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.productgroups.items,
  loading: state.productgroups.loading,
  currentProductgroup: state.productgroups.currentProductgroup,
  currentParentgroup: state.productgroups.currentParentgroup,
  isNews: state.products.news,
  isSeasonNews: state.products.seasonNews,
  lng: state.app.language,
  loggedIn: state.user.login.loggedIn,
  showmypageheaders: state.productgroups.showMypageNavigation,
  settings: state.app.settings,
  currentLanguage: state.app.currentLanguage
})

export default withRouter(connect(mapStateToProps)(ProductGroups))
