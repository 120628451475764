import React, { PureComponent } from 'react'

import './style.scss'

class GalleryItem extends PureComponent {
  onClick = () => {
    if(this.props.onClick)
      this.props.onClick(this.props.itemId)
  }

  render() {
    const {
      itemId
    } = this.props

    const imageUrl = `${process.env.REACT_APP_API_URL}/api/WebImageThumbnail/${itemId}`

    return (
      <div className="galleryItem" onClick={this.onClick}>
        <img src={imageUrl} alt="gallery" />
      </div>
    )
  }
}

export default GalleryItem