import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { format } from 'lib/utils'
import Button from 'components/common/Button'
import DefaultSelect from 'components/common/inputs/DefaultSelect'
import './style.scss'

class ArticlePrice extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    lng: PropTypes.object.isRequired,
    addQuantities: PropTypes.array.isRequired,
    quantity : PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired
  }

  render() {
    const {
      lng,
      article,
      addQuantities,
      quantity,
      onChange,
      addToCart
    } = this.props

    return (
      <div className="articlePrice row">
        <div className="price col-x-12">
          <label htmlFor="">{lng.Common.Price}: </label>
          <span>{format(article.Price)}/St</span>
        </div>
        <div className="quantityAddToCart col-xs-12 middle-xs row">
          <div className="containerDiv col-xs-2">
            <label className="selectLabel" htmlFor="quantity"></label>
            <DefaultSelect className="quantitySelect" name="quantity" defaultValue={quantity} onChange={onChange}>
              {
                addQuantities.map(q => <option key={q} value={q}>{q} st</option>)
              }
            </DefaultSelect>
          </div>
          <Button className="light col-xs-4" type="button" onClick={addToCart} text={lng.Product.AddToCart}></Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(ArticlePrice)
