import React, { Component } from 'react'
import {
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom'
import { connect } from 'react-redux'
import {
  testLocalstorage
} from 'lib/utils'
import {
  fetchSettings,
  fetchLanguage,
  fetchWebTexts,
  fetchCountries
} from './duck'
import {
  checkLogin
} from 'features/User/LoginWidget/duck'
import {
  checkCookie
} from 'features/CookieFeature/duck'
import {
  fetchDueInvoices
} from 'features/DueInvoices/duck'
import './App.scss'

import CookieFeature from 'features/CookieFeature'
import CookieText from 'features/CookieFeature/CookieText'
import PrivateRoute from 'components/common/PrivateRoute'

import StartPage from 'pages/StartPage'
import RequireLoginPage from 'pages/RequireLoginPage'
import Products from 'features/Products'
import ArticleFeature from 'features/ArticleFeature'
import NotFoundPage from 'pages/NotFoundPage'
import Cart from 'features/ShoppingCart/Cart'

import UserProfile from 'features/MyPage/UserProfile'
import DeliveryAddresses from 'features/MyPage/DeliveryAddresses'
import Orders from 'features/MyPage/Orders'
import Invoices from 'features/MyPage/Invoices'
import DueInvoices from 'features/DueInvoices'
import Pressroom from 'features/Pressroom'
import SetNewPassword from 'features/User/SetNewPassword'
import ResetPassword from 'features/User/ResetPassword'
import { ErrorBoundary } from '@sentry/react'

import BasicLayout from 'layout/BasicLayout/BasicLayout'
import ShopLayout from 'layout/ShopLayout/ShopLayout'
import TermsPage from 'pages/TermsPage'
import ContactPage from 'pages/ContactPage'
import ApplicationPage from 'pages/Application'

class App extends Component {

  componentWillMount() {
    let languageCode = 'sv'
    if (testLocalstorage()) {
      languageCode = localStorage.getItem('language') || 'sv'
    }

    this.props.dispatch(fetchSettings())
    this.props.dispatch(fetchWebTexts(languageCode))
    this.props.dispatch(fetchLanguage(languageCode))
    this.props.dispatch(checkLogin())
    this.props.dispatch(checkCookie())
    this.props.dispatch(fetchCountries())
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.loggedIn && nextProps.loggedIn)
      this.props.dispatch(fetchDueInvoices())
  }

  render() {
    const cookieFeature = this.props.cookie.checked && !this.props.cookie.accepted ? <CookieFeature /> : null
    const cookieText = this.props.cookie.showCookies ? <CookieText /> : null
    const dueInvoices = this.props.showDueInvoicesWindow ? <DueInvoices /> : null
    const setNewPassword = this.props.requireNewPassword && !this.props.authUsed ? <SetNewPassword /> : null
    const resetPassword = this.props.resetPassword ? <ResetPassword /> : null

    return (
      <ErrorBoundary>
        <Router>
          <div className="app">
            <Switch>
              <PrivateRoute layout={ShopLayout} component={UserProfile} path="/userprofile" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute layout={ShopLayout} component={DeliveryAddresses} path="/deliveryaddresses" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute layout={ShopLayout} component={Orders} path="/orders" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute layout={ShopLayout} component={Invoices} path="/invoices" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute layout={ShopLayout} component={Cart} path="/cart" authenticated={this.props.loggedIn} initialCheck={this.props.initialCheck} exact />
              <PrivateRoute layout={ShopLayout} component={ArticleFeature} path="/article/:id/:articleName" public exact />
              <PrivateRoute layout={ShopLayout} component={Products} path="/products" public exact />
              <PrivateRoute layout={ShopLayout} component={Products} path="/products/:productGroupId/:produtGroupName?" public exact />
              <PrivateRoute layout={ShopLayout} component={Pressroom} path="/pressroom/:section*" public exact />
              <PrivateRoute layout={ShopLayout} component={Pressroom} path="/pressroom" public exact />
              <PrivateRoute layout={ShopLayout} component={RequireLoginPage} path="/requirelogin" public exact />
              <PrivateRoute layout={BasicLayout} component={TermsPage} path="/koepvillkor" public exact />
              <PrivateRoute layout={BasicLayout} component={ContactPage} path="/kontakt" public exact />
              <PrivateRoute layout={BasicLayout} component={ApplicationPage} path="/application" public exact />
              <PrivateRoute layout={BasicLayout} component={StartPage} path="/start/:section" public exact />
              <PrivateRoute layout={BasicLayout} component={StartPage} path="/" public exact />
              <PrivateRoute layout={BasicLayout} component={NotFoundPage} path="*" public />
            </Switch>
            {cookieFeature}
            {cookieText}
            {dueInvoices}
            {setNewPassword}
            {resetPassword}
          </div>
        </Router>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  cookie: state.cookies,
  resellers: state.resellers,
  loggedIn: state.user.login.loggedIn,
  initialCheck: state.user.login.initialCheck,
  authUsed: state.user.login.authUsed,
  showDueInvoicesWindow: state.dueinvoices.showWindow,
  requireNewPassword: state.user.setnewpassword.requireNewPassword,
  resetPassword: state.user.resetpassword.showWindow
})

export default connect(mapStateToProps)(App)
