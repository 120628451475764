import initialState from './initialState'

const SHOW_WIDGET = 'md/features/SearchWidget/SHOW_WIDGET'
const HIDE_WIDGET = 'md/features/SearchWidget/HIDE_WIDGET'
const TOGGLE_WIDGET = 'md/features/SearchWidget/TOGGLE_WIDGET'

const FORM_CHANGED = 'md/features/SearchWidget/FORM_CHANGED'
const CLEAR_FORM = 'md/features/SearchWidget/CLEAR_FORM'

//Actions
export const showWidget = () => {
  return {
    type: SHOW_WIDGET
  }
}

export const hideWidget = () => {
  return {
    type: HIDE_WIDGET
  }
}

export const toggleWidget = () => {
  return {
    type: TOGGLE_WIDGET
  }
}

export const formChanged = (name, value) => {
  return {
    type: FORM_CHANGED,
    name,
    value
  }
}

export const clearForm = () => {
  return {
    type: CLEAR_FORM
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_WIDGET:
      return {
        ...state,
        showWidget: true
      }
    case HIDE_WIDGET:
      return {
        ...state,
        showWidget: false
      }
    case TOGGLE_WIDGET:
      return {
        ...state,
        showWidget: !state.showWidget
      }
    case FORM_CHANGED:
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value
        }
      }
    case CLEAR_FORM:
      return {
        ...state,
        form: {
          searchtext: '',
          news: false,
          colors: [],
          material: null,
          productgroupid: null
        }
      }
    default:
      return state
  }
}