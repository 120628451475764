import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchProducts
} from './duck'
import {
  addToCart
} from 'features/ShoppingCart/duck'
import './style.scss'

import LoadingSpinner from 'components/common/LoadingSpinner'
import ProductCard from 'components/common/ProductCard'
import MDLogo from 'images/logo_sign_transparent_beige.png'
import SearchFeatures from 'components/shop/SearchFeatures/SearchFeatures'

export class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderMonths: null,
      orderMonthsSeason: null,
      newsText: null,
      seasonNewsText: null
    }
  }

  setLanguageTexts = (languageCode) => {
    const {
      settings
    } = this.props

    if (!settings || !settings.OrderMonthSV)
      return

    const isSwedish = languageCode === 'sv'

    const texts = {
      orderMonths: isSwedish ? settings.OrderMonthSV : settings.OrderMonthEN,
      orderMonthsSeason: isSwedish ? settings.OrderMonthsSeasonSV : settings.OrderMonthsSeasonEN,
      newsText: isSwedish ? settings.ArticleNewsTextSV : settings.ArticleNewsTextEN,
      seasonNewsText: isSwedish ? settings.ArticleSeasonNewsTextSV : settings.ArticleSeasonNewsTextEN,
    }

    this.setState(texts)
  }

  getProducts = (productGroupId) => {
    const news = productGroupId === null
    const seasonNews = productGroupId === 'seasonnews'

    if (news || seasonNews)
      productGroupId = null

    this.props.dispatch(fetchProducts(productGroupId, news, seasonNews, this.props.showOnlyStockArticles))
  }

  addToCart = (articleId, quantity) => {
    this.props.dispatch(addToCart(articleId, quantity))
  }

  componentWillMount() {
    const {
      match,
      search
    } = this.props

    const params = match.params
    const productGroupId = params.productGroupId || null
    this.setLanguageTexts(this.props.currentLanguage)

    if (!search) {
      this.getProducts(productGroupId)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      search,
      loggedIn,
      currentLanguage,
      settings
    } = nextProps

    const params = match.params
    const productGroupId = this.props.match.params.productGroupId || null
    const newProductGroupId = params.productGroupId || null
    const hasLoggedIn = !this.props.loggedIn && loggedIn

    if ((productGroupId !== newProductGroupId && (!search || newProductGroupId !== null)) || hasLoggedIn) {
      this.getProducts(newProductGroupId)
    }

    if (currentLanguage !== this.props.currentLanguage || (settings && settings.OrderMonthSV && !this.state.orderMonths))
      this.setLanguageTexts(currentLanguage)
  }

  render() {
    const loading = this.props.loading ? <LoadingSpinner /> : null

    const {
      productGroups,
      lng,
      search,
      news,
      seasonNews,
      currentLanguage,
      settings
    } = this.props

    const productGroup = productGroups ? productGroups.find(group => group.ProductGroupId === Number(this.props.match.params.productGroupId)) : null

    let productGroupName
    let parentProductGroupName

    if (news) {
      productGroupName = lng.Products.News
    } else if (seasonNews) {
      const seasonNews = settings['SeasonNewsMenu' + currentLanguage.toUpperCase()]
      productGroupName = seasonNews
    } else if (search) {
      productGroupName = lng.Products.FoundProducts
    } else {
      let parentProductGroup
      if (productGroup && productGroup.ParentId)
        parentProductGroup = this.props.productGroups ? this.props.productGroups.find(group => group.ProductGroupId === productGroup.ParentId) : null

      productGroupName = productGroup ? productGroup.Name : ''
      parentProductGroupName = parentProductGroup ? parentProductGroup.Name + ' > ' : ''
    }

    return (
      <div className="products">
        <div className="topContainer row">
          <div className='col-xs-12 col-md-4'>
            <SearchFeatures />
          </div>
          <div className='productGroup col-xs-12 col-md-4'>
            <img src={MDLogo} alt="MD" />
            <h3>{parentProductGroupName}{productGroupName}</h3>
          </div>
        </div>
        {loading}
        <div className="productsWrapper">
          {
            this.props.items && this.props.items.length ?
              this.props.items.map(item => (<ProductCard
                key={item.Id}
                article={item}
                news={this.props.news}
                seasonNews={this.props.seasonNews}
                lng={this.props.lng}
                addToCart={this.addToCart}
                showTall={this.props.loggedIn}
                orderMonths={this.state.orderMonths}
                orderMonthsSeason={this.state.orderMonthsSeason}
                newsText={this.state.newsText}
                seasonNewsText={this.state.seasonNewsText}
                currentLanguage={currentLanguage} />))
              : null
          }
          {
            this.props.items && this.props.items.length === 0 && !this.props.loading ?
              (<div>Hittade inga artiklar...</div>) : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.products.loading,
  items: state.products.items,
  news: state.products.news,
  seasonNews: state.products.seasonNews,
  search: state.products.search,
  lng: state.app.language,
  loggedIn: state.user.login.loggedIn,
  productGroups: state.productgroups.items,
  settings: state.app.settings,
  currentLanguage: state.app.currentLanguage,
  showOnlyStockArticles: state.products.onlyStockArticles
})

export default connect(mapStateToProps)(Products)
