import React, { PureComponent } from 'react'
import GalleryItem from './GalleryItem'
import PropTypes from 'prop-types'

import './style.scss'

class Gallery extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    onClick: PropTypes.func.isRequired
  }

  onItemClick = (id) => {
    this.props.onClick(id)
  }

  render() {
    const {
      items
    } = this.props

    let noItems = null
    if (items && !items.length)
      noItems = 'Nothing here yet...'

    return (
      <div className="gallery">
        {
          items ? items.map(item => (
            <GalleryItem key={item.Id} itemId={item.Id} onClick={this.onItemClick} />
          )) : null
        }
        {noItems}
      </div>
    )
  }
}

export default Gallery