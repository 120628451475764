import { combineReducers } from 'redux'
import app from 'features/App/duck'
import products from 'features/Products/duck'
import search from 'features/SearchWidget/duck'
import user from 'features/User/duck'
import aboutus from 'features/AboutUs/duck'
import productgroups from 'features/ProductGroups/duck'
import resellers from 'features/Resellers/duck'
import shoppingcart from 'features/ShoppingCart/duck'
import article from 'features/ArticleFeature/duck'
import cookies from 'features/CookieFeature/duck'
import colors from 'features/Colors/duck'
import materials from 'features/Materials/duck'
import intro from 'features/Intro/duck'
import mypage from 'features/MyPage/duck'
import dueinvoices from 'features/DueInvoices/duck'
import pressroom from 'features/Pressroom/duck'

const rootReducer = combineReducers({
  app,
  article,
  cookies,
  colors,
  aboutus,
  intro,
  materials,
  productgroups,
  products,
  user,
  resellers,
  search,
  shoppingcart,
  mypage,
  dueinvoices,
  pressroom
})

export default rootReducer