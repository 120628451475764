import initialState from './initialState'

const FETCH_COLORS = 'md/features/Colors/FETCH_COLORS'
const FETCH_COLORS_SUCCESS = 'md/features/Colors/FETCH_COLORS_SUCCESS'
const FETCH_COLORS_FAILED = 'md/features/Colors/FETCH_COLORS_FAILED'


export const fetchColors = () => {
  return {
    types: [FETCH_COLORS, FETCH_COLORS_SUCCESS, FETCH_COLORS_FAILED],
    callApi: {
      endpoint: 'api/colors/',
      method: 'GET'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COLORS:
      return {
        ...state,
        loading: true,
        items: null
      }
    case FETCH_COLORS_SUCCESS:
      return {
        ...state,
        items: action.data,
        loading: false
      }
    case FETCH_COLORS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}