import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  toggleForm,
  addressChange,
  fetchAddress,
  updateAddress,
  addAddress
} from '../duck'

import {
  validate,
  validateObject
} from 'lib/validator'
import validationRules from '../validationRules'

import FloatingWindow from 'components/containers/FloatingWindow'
import DefaultInput from 'components/common/inputs/DefaultInput'
import DefaultSelect from 'components/common/inputs/DefaultSelect'
import Button from 'components/common/Button'
import './style.scss'
import LoadingOverlay from 'components/common/LoadingOverlay';

export class Addressform extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {}
    }
  }

  onChange = (e) => {
    const {
      name,
      value,
      type,
      checked
    } = e.target

    if (type === 'checkbox') {
      this.props.dispatch(addressChange(name, checked))
    } else
      this.props.dispatch(addressChange(name, value))

    this.validateInput(e)
  }

  onClick = (e) => {
    e.preventDefault()
    const payload = {
      ...this.props.deliveryAddress
    }
    const addressId = this.props.deliveryAddress.Id

    const {
      lng
    } = this.props

    const validations = validationRules(lng.ErrorTexts)

    const validate = validateObject(validations, payload)

    this.setState({
      errors: {
        ...validate.properties
      }
    })

    if (validate.valid) {
      if (addressId)
        this.props.dispatch(updateAddress(addressId, payload))
      else
        this.props.dispatch(addAddress(payload))
    }
  }

  toggleForm = () => {
    this.props.dispatch(toggleForm())
  }

  componentWillMount() {
    if (this.props.addressId)
      this.props.dispatch(fetchAddress(this.props.addressId))
  }

  validateInput = e => {
    const {
      name,
      value
    } = e.target

    const {
      lng
    } = this.props

    const validations = validationRules(lng.ErrorTexts)

    const validation = validate(validations[name], value)
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: validation.error
      }
    })
  }

  render() {
    const {
      lng,
      countries,
      deliveryAddress,
      loading
    } = this.props

    return (
      <FloatingWindow showHeader={false} closeOutSide={true} draggable={false} onClose={this.toggleForm}>
        <div className="deliveryAddresses">
          <form onSubmit={this.onClick}>
            <DefaultInput name="Name" type="text" label={lng.CustomerDetails.Name} value={deliveryAddress.Name} onChange={this.onChange} error={this.state.errors.Name} />
            <DefaultInput name="Address1" type="text" label={lng.CustomerDetails.Address1} value={deliveryAddress.Address1 || ''} onChange={this.onChange} error={this.state.errors.Address1} />
            <DefaultInput name="Address2" type="text" label={lng.CustomerDetails.Address2} value={deliveryAddress.Address2 || ''} onChange={this.onChange} error={this.state.errors.Address2} />
            <DefaultInput name="ZipCode" type="text" label={lng.CustomerDetails.ZipCode} value={deliveryAddress.ZipCode} onChange={this.onChange} error={this.state.errors.ZipCode} />
            <DefaultInput name="City" type="text" label={lng.CustomerDetails.City} value={deliveryAddress.City} onChange={this.onChange} error={this.state.errors.City} />
            <div className="selectContainer">
              <DefaultSelect name="CountryCode" label={lng.CustomerDetails.Country} value={deliveryAddress.CountryCode} onChange={this.onChange}>
                <option value="">Välj</option>
                {
                  countries ? countries.map(country => {
                    return (<option key={country.CountryCode} value={country.CountryCode}>{country.CountryName}</option>)
                  })
                    : null
                }
              </DefaultSelect>
            </div>
            <DefaultInput name="ContactPerson" type="text" label={lng.CustomerDetails.ContactPerson} value={deliveryAddress.ContactPerson || ''} onChange={this.onChange} error={this.state.errors.ContactPerson} />
            <div className="buttonWrapper">
              <Button type="submit" text={this.props.addressId ? lng.DeliveryAddress.ChangeDeliveryAddress : lng.DeliveryAddress.CreateAddress} />
            </div>
            {loading ? <LoadingOverlay /> : null}
          </form>
        </div>
      </FloatingWindow>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  countries: state.app.countries,
  addressId: state.mypage.deliveryaddresses.addressId,
  deliveryAddress: state.mypage.deliveryaddresses.deliveryAddress,
  loading: state.mypage.deliveryaddresses.loading
})

export default connect(mapStateToProps)(Addressform)
