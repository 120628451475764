import React, { Component } from 'react'
import { connect } from 'react-redux'
import FloatingWindow from 'components/containers/FloatingWindow'
import RawHTML from 'components/common/RawHTML'
import Button from 'components/common/Button'
import './style.scss'

import {
  toggleCookies
} from './../duck'

export class CookieText extends Component {
  toggleCookies = () => {
    this.props.dispatch(toggleCookies())
  }

  render() {
    return (
      <FloatingWindow showHeader={false} closeOutSide={true} draggable={false} onClose={this.toggleCookies}>
        <div className="cookiePage">
          <div className="cookieTextContainer">
            <RawHTML nl2br={true} content={this.props.webtexts.Cookies || ''}></RawHTML>
            <span>Du kan läsa mer om cookies på <a href="https://www.pts.se/sv/privat/internet/integritet/kakor-cookies/" rel="nofollow noopener noreferrer" target="_blank">Post- och Telestyrelsens webbplats</a>.</span>
          </div>
          <div className="buttonContainer">
            <Button className="closeCookieButton" onClick={this.toggleCookies} type="button" text={this.props.lng.Common.Close}></Button>
          </div>
        </div>
      </FloatingWindow>
    )
  }
}

const mapStateToProps = (state) => ({
  webtexts: state.app.webTexts,
  lng: state.app.language
})

export default connect(mapStateToProps)(CookieText)
