import React, { PureComponent } from 'react'
import { format } from 'lib/utils'

export class OrderRowsTable extends PureComponent {
  render() {
    const {
      lng,
      rows
    } = this.props

    return (
      <tr className="subtable">
        <td colSpan="20">
          <table>
            <thead>
              <tr>
                <th className="left articleNumber">{lng.Common.ArticleNumber}</th>
                <th className="left">{lng.Common.Description}</th>
                <th className="right">{lng.Common.Price}</th>
                <th className="right">{lng.Common.Quantity}</th>
                <th className="right">{lng.Orders.TotalAmount}</th>
              </tr>
            </thead>
            <tbody>
              {rows.map(row => (
                <tr key={row.Id}>
                  <td className="left">{row.ArticleNumber}</td>
                  <td className="left">{row.Description}</td>
                  <td className="right">{format(row.Price)}</td>
                  <td className="right">{row.Quantity}</td>
                  <td className="right">{format(row.TotalAmount)}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        </td>
      </tr>
    )
  }
}

export default OrderRowsTable