import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  fetchGallery,
  showSlider,
  hideSlider
} from './duck'

import Gallery from 'components/common/Gallery'
import GallerySlider from 'components/common/GallerySlider'
import LoadingSpinner from 'components/common/LoadingSpinner'

import './style.scss'

class PressGallery extends PureComponent {
  openSlider = (imageId) => {
    this.props.dispatch(showSlider(imageId))
  }

  closeSlider = () => {
    this.props.dispatch(hideSlider())
  }

  componentWillMount() {
    const {
      loading,
      items
    } = this.props

    if (!loading && !items)
      this.props.dispatch(fetchGallery())
  }

  render() {
    const {
      loading,
      items,
      showSlider,
      sliderItem
    } = this.props

    return (
      <div className="pressGallery">
        <h3 className="underline">Bilder/Filmer</h3>
        {
          loading ? <LoadingSpinner /> : null
        }
        <Gallery items={items} onClick={this.openSlider} />
        {
          showSlider ? <GallerySlider items={items} startItem={sliderItem} onClose={this.closeSlider} /> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.pressroom.gallery.loading,
  items: state.pressroom.gallery.items,
  showSlider: state.pressroom.gallery.showSlider,
  sliderItem: state.pressroom.gallery.sliderItem
})

export default connect(mapStateToProps)(PressGallery)