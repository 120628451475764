import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import { format } from 'lib/utils'

import {
  fetchInvoices
} from 'features/MyPage/Invoices/duck'

import pdf from 'images/pdf.gif'

import Pagination from 'components/common/Pagination'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

export class Invoices extends Component {
  onChangePage = page => {
    this.props.dispatch(fetchInvoices(page))
  }

  getStatusText = invoice => {
    const {
      lng
    } = this.props

    if (invoice.IsPayed && invoice.PayDate) {
      return `${lng.Invoices.Paid} ${moment(invoice.PayDate).format('YYYY-MM-DD')}`
    } else if (invoice.IsPayed) {
      return lng.Invoices.Paid
    } else if (invoice.IsAnnulated) {
      return lng.Invoices.Annulated
    } else {
      return lng.Invoices.UnPaid
    }
  }

  downloadInvoice = invoiceNumber => {
    window.open(`${process.env.REACT_APP_API_URL}/api/pdf/Invoice/${invoiceNumber}`, '_blank')
  }

  componentWillMount() {
    const {
      loading
    } = this.props

    if (!loading) {
      this.props.dispatch(fetchInvoices(1))
    }
  }

  render() {
    const {
      loading,
      invoicedata,
      lng,
      currentPage,
      take
    } = this.props

    const pagination = <Pagination onChange={this.onChangePage} currentItem={currentPage} take={take} totalCount={invoicedata ? invoicedata.TotalCount : 0} />


    let noRows = null
    if (invoicedata && invoicedata.Invoices.length === 0 & !loading) {
      noRows = (
        <tr>
          <td colSpan="10" className="noRows">{lng.Common.NoRowsFound}</td>
        </tr>
      )
    }

    return (
      <div className="invoices">
        {pagination}
        <table className="default">
          <thead>
            <tr>
              <th>{lng.Invoices.InvoiceNumber}</th>
              <th>{lng.Invoices.InvoiceDate}</th>
              <th>{lng.Invoices.DueDate}</th>
              <th>{lng.Invoices.TermsOfPayment}</th>
              <th>{lng.Invoices.Status}</th>
              <th className="right">{lng.Common.TotalAmount}</th>
              <th className="right">{lng.Invoices.Balance}</th>
              <th className="center">{lng.Common.Currency}</th>
              <th className="center">PDF</th>
            </tr>
          </thead>
          <tbody>
            {
              invoicedata && invoicedata.Invoices ? invoicedata.Invoices.map(invoice =>
                <tr key={invoice.InvoiceNumber}>
                  <td>{invoice.InvoiceNumber}</td>
                  <td>{moment(invoice.CreateDate).format('YYYY-MM-DD')}</td>
                  <td>{moment(invoice.DueDate).format('YYYY-MM-DD')}</td>
                  <td>{invoice.TermsOfPayment} {lng.Common.Days}</td>
                  <td className={classNames({ paid: invoice.IsPayed, unpaid: !invoice.IsPayed })}>{this.getStatusText(invoice)}</td>
                  <td className="right">{format(invoice.TotalAmount)}</td>
                  <td className="right">{format(invoice.Balance)}</td>
                  <td className="center">{invoice.CurrencyCode}</td>
                  <td className="center"><img className="pdf" onClick={() => this.downloadInvoice(invoice.InvoiceNumber)} src={pdf} alt="PDF" /></td>
                </tr>
              ) : null
            }
            {noRows}
          </tbody>
        </table>
        {pagination}
        {loading ? <LoadingOverlay /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.mypage.invoices.loading,
  invoicedata: state.mypage.invoices.data,
  currentPage: state.mypage.invoices.currentPage,
  take: state.mypage.invoices.take,
  lng: state.app.language
})

export default connect(mapStateToProps)(Invoices)
