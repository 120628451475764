import initialState from './initialState'

import {
  COMMON_LOGGEDOUT 
} from 'redux/commonTypes'

const FETCH_CART = 'md/features/ShoppingCart/FETCH_CART'
const FETCH_CART_SUCCESS = 'md/features/ShoppingCart/FETCH_CART_SUCCESS'
const FETCH_CART_FAILED = 'iam/features/shoppingcart/FETCH_CART_FAILED'

const ADD_TO_CART = 'md/features/ShoppingCart/ADD_TO_CART'
const ADD_TO_CART_SUCCESS = 'md/features/ShoppingCart/ADD_TO_CART_SUCCESS'
const ADD_TO_CART_FAILED = 'md/features/ShoppingCart/ADD_TO_CART_FAILED'

const DELETE_CART_ITEM = 'md/features/ShoppingCart/DELETE_CART_ITEM'
const DELETE_CART_ITEM_SUCCESS = 'md/features/ShoppingCart/DELETE_CART_ITEM_SUCCESS'
const DELETE_CART_ITEM_FAILED = 'md/features/ShoppingCart/DELETE_CART_ITEM_FAILED'

const UPDATE_CART_ITEM = 'md/features/ShoppingCart/UPDATE_CART_ITEM'
const UPDATE_CART_ITEM_SUCCESS = 'md/features/ShoppingCart/UPDATE_CART_ITEM_SUCCESS'
const UPDATE_CART_ITEM_FAILED = 'md/features/ShoppingCart/UPDATE_CART_ITEM_FAILED'

const CHECKOUT_CHANGE = 'md/features/ShoppingCart/CHECKOUT_CHANGE'
const CHECKOUT_DELIVERYWEEK_CHANGE = 'md/features/ShoppingCart/CHECKOUT_DELIVERYWEEK_CHANGE'
const CHECKOUT_DELIVERYWEEK_NUMBER_CHANGE = 'md/features/ShoppingCart/CHECKOUT_DELIVERYWEEK_NUMBER_CHANGE'

const SEND_ORDER = 'md/features/ShoppingCart/SEND_ORDER'
const SEND_ORDER_SUCCESS = 'md/features/ShoppingCart/SEND_ORDER_SUCCESS'
const SEND_ORDER_FAILED = 'md/features/ShoppingCart/SEND_ORDER_FAILED'

const FETCH_WAYOFDELIVERIES = 'md/features/ShoppingCart/FETCH_WAYOFDELIVERIES'
const FETCH_WAYOFDELIVERIES_SUCCESS = 'md/features/ShoppingCart/FETCH_WAYOFDELIVERIES_SUCCESS'
const FETCH_WAYOFDELIVERIES_FAILED = 'md/features/ShoppingCart/FETCH_WAYOFDELIVERIES_FAILED'

const RESET_ORDER_COMPLETE = 'md/features/ShoppingCart/RESET_ORDER_COMPLETE'

//Actions
export const fetchCart = () => {
  return {
    types: [FETCH_CART, FETCH_CART_SUCCESS, FETCH_CART_FAILED],
    callApi: {
      endpoint: 'api/cart',
      method: 'GET'
    }
  }
}

export const addToCart = (articleId, quantity) => {
  const articleObj = {
    ArticleId: articleId,
    Quantity: quantity,
    QuickAdd: false
  }
  return {
    types: [ADD_TO_CART, ADD_TO_CART_SUCCESS, ADD_TO_CART_FAILED],
    callApi: {
      endpoint: 'api/cartrow',
      method: 'POST',
      data: articleObj
    }
  }
}

export const removeCartArticle = (id) => {
  return {
    types: [DELETE_CART_ITEM, DELETE_CART_ITEM_SUCCESS, DELETE_CART_ITEM_FAILED],
    callApi: {
      endpoint: 'api/cartrow/' + id,
      method: 'DELETE'
    }
  }
}

export const updateCartArticle = (id, quantity) => {
  const quantityObj = {
    Quantity: quantity
  }
  return {
    types: [UPDATE_CART_ITEM, UPDATE_CART_ITEM_SUCCESS, UPDATE_CART_ITEM_FAILED],
    callApi: {
      endpoint: 'api/cartrow/' + id,
      method: 'PUT',
      data: quantityObj
    }
  }
}

export const checkoutChange = (name, value) => {
  return {
    type: CHECKOUT_CHANGE,
    name,
    value
  }
}

export const checkoutDeliveryWeekChange = (name, value) => {
  return {
    type: CHECKOUT_DELIVERYWEEK_CHANGE,
    name,
    value
  }
}

export const checkoutDeliveryDateNumber = (number, weekstring) => {
  return {
    type: CHECKOUT_DELIVERYWEEK_NUMBER_CHANGE,
    number,
    weekstring
  }
}

export const sendOrder = (criteria) => {
  return {
    types: [SEND_ORDER, SEND_ORDER_SUCCESS, SEND_ORDER_FAILED],
    callApi: {
      endpoint: 'api/Order',
      method: 'POST',
      data: criteria
    }
  }
}

export const fetchWayOfDeliveries = () => {
  return {
    types: [FETCH_WAYOFDELIVERIES, FETCH_WAYOFDELIVERIES_SUCCESS, FETCH_WAYOFDELIVERIES_FAILED],
    callApi: {
      endpoint: 'api/wayofdelivery',
      method: 'GET'
    }
  }
}

export const resetOrderComplete = () => {
  return {
    type: RESET_ORDER_COMPLETE
  }
}

//Reducer 
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CART:
      return {
        ...state,
        loading: true
      }
    case FETCH_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case FETCH_CART_FAILED:
      return {
        ...state,
        loading: false,
      }
    case ADD_TO_CART:
      return {
        ...state,
        loading: true
      }
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case ADD_TO_CART_FAILED:
      return {
        ...state,
        loading: false,
      }
    case DELETE_CART_ITEM:
      return {
        ...state,
        loading: true
      }
    case DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case DELETE_CART_ITEM_FAILED:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_CART_ITEM:
      return {
        ...state,
        loading: true
      }
    case UPDATE_CART_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case UPDATE_CART_ITEM_FAILED:
      return {
        ...state,
        loading: false,
      }
    case CHECKOUT_CHANGE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          [action.name]: action.value
        }
      }
    case CHECKOUT_DELIVERYWEEK_CHANGE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          [action.name]: action.value
        }
      }
    case CHECKOUT_DELIVERYWEEK_NUMBER_CHANGE:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          deliveryWeekNumber: action.number,
          deliveryWeekString: action.weekstring
        }
      }
    case COMMON_LOGGEDOUT: 
      return {
        ...state,
        orderId: null,
        error: false,
        data: null,
        checkout: {
          orderInfoText: '',
          wayOfDeliveryId: null,
          deliveryWeek: null,
          deliveryWeekNumber: null,
          partialDelivery: null,
          deliveryYear: null,
          deliveryWeekString: null
        }
      }
    case SEND_ORDER:
      return {
        ...state,
        loading: true,
        orderComplete: false,
        orderId: null,
        error: false
      }
    case SEND_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderComplete: true,
        orderId: action.data
      }
    case SEND_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    case FETCH_WAYOFDELIVERIES_SUCCESS:
      return {
        ...state,
        wayOfDeliveries: action.data
      }
    case RESET_ORDER_COMPLETE: 
      return {
        ...state,
        orderComplete: false,
        orderId: null
      }
    default:
      return state
  }
}