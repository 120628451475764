import initialState from './initialState'

const FETCH_SAIDABOUTOUS = 'md/features/Pressroom/pages/SaidAboutUs/FETCH_SAIDABOUTOUS'
const FETCH_SAIDABOUTOUS_SUCCESS = 'md/features/Pressroom/pages/SaidAboutUs/FETCH_SAIDABOUTOUS_SUCCESS'
const FETCH_SAIDABOUTOUS_FAILED = 'md/features/Pressroom/pages/SaidAboutUs/FETCH_SAIDABOUTOUS_FAILED'

export const fetchLinks = (laguagecode) => {
  const payload = {
    categoryId: 3,
    languageCode: laguagecode
  }

  return {
    types: [FETCH_SAIDABOUTOUS, FETCH_SAIDABOUTOUS_SUCCESS, FETCH_SAIDABOUTOUS_FAILED],
    callApi: {
      endpoint: 'api/pressroommessages',
      method: 'GET',
      data: payload
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SAIDABOUTOUS:
      return {
        ...state,
        loading: true
      }
    case FETCH_SAIDABOUTOUS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_SAIDABOUTOUS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}