import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import noPicture from 'images/nopic.jpg'
import RawHTML from 'components/common/RawHTML'
import LoadingOverlay from 'components/common/LoadingOverlay'

import './style.scss'

import {
  fetchpressarticle
} from './duck'

class PressProduct extends PureComponent {

  fetchProduct = (languageCode) => {
    const articleId = this.props.match.params.id
    this.props.dispatch(fetchpressarticle(articleId, languageCode))
  }

  componentWillMount = () => {
    this.fetchProduct(this.props.currentLanguage)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentLanguage !== nextProps.currentLanguage)
      this.fetchProduct(nextProps.currentLanguage)
  }

  render() {
    const {
      pressarticle,
      loading,
      lng
    } = this.props

    const imageUrl = pressarticle.item ? `${process.env.REACT_APP_API_URL}/api/ArticleImage/${pressarticle.item.ImageId}` : noPicture

    return (
      <div className="pressProduct">
        {loading ? <LoadingOverlay /> : null}
        {
          pressarticle.item ?
            <div>
              <Link className="backLink" to="/pressroom/moomin">{lng.PressRoom.GoBack}</Link>
              <div className="productContainer row">
                <div className="imageContainer col-sm-5">
                  <img src={imageUrl} alt="article" />
                </div>
                <div className="textContainer col-sm-6">
                  <h2 className="header">{lng.PressRoom.Description}</h2>
                  <div className="pressArticleText">
                    <RawHTML nl2br={true} content={pressarticle.item ? pressarticle.item.Text : ''} />
                  </div>
                </div>
              </div>
            </div> : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  pressarticle: state.pressroom.pressproduct,
  loading: state.pressroom.pressproduct.loading,
  currentLanguage: state.app.currentLanguage,
  lng: state.app.language
})

export default connect(mapStateToProps)(PressProduct)