import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Route,
  Switch,
} from 'react-router-dom'

//layout
import HeaderImage from './HeaderImage'
import PressNavigation from './PressNavigation'
import PressContact from './PressContact'

//pages
import PressMessages from './pages/PressMessages'
import PressMessage from './pages/PressMessage'
import SaidAboutUs from './pages/SaidAboutUs'
import PressGallery from './pages/PressGallery'
import NewsLetters from './pages/NewsLetters'
import PressProduct from './pages/PressProduct'
import Moomin from './pages/Moomin'

import './style.scss'

export class Pressroom extends Component {
  render() {
    const {
      lng,
      match
    } = this.props

    const section = match.params.section ? match.params.section : null

    return (
      <div className="pressroom">
        <div>
          <h3>{lng.PressRoom.PressRoom}</h3>
          <div className="row">
            <div className="bigImage col-xs-12 col-sm-8">
              <HeaderImage />
            </div>
            <div className="navigationWrapper first-xs last-sm col-xs-12 col-sm-4 row">
              <div className="navigation col-xs-8 col-sm-12">
                <PressNavigation section={section} />
              </div>
              <div className="contact col-xs-4 col-sm-12">
                <PressContact />
              </div>
            </div>
          </div>
          <div className="content">
            <Switch>
              <Route component={NewsLetters} path="/pressroom/newsletters" exact />
              <Route component={PressGallery} path="/pressroom/gallery" exact />
              <Route component={SaidAboutUs} path="/pressroom/aboutus" exact />
              <Route component={PressMessages} path="/pressroom/press" exact />
              <Route component={PressMessage} path="/pressroom/press/:id/:urlname" exact />
              <Route component={PressProduct} path="/pressroom/product/:id/:urlname" exact />
              <Route component={Moomin} path="/pressroom/moomin" exact />
              <Route component={Moomin} path="/pressroom" exact />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language
})

export default connect(mapStateToProps)(Pressroom)
