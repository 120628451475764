import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LoadingOverlay from 'components/common/LoadingOverlay'
import PressMessageCard from 'components/common/PressMessageCard'

import {
  fetchLinks
} from './duck'

import './style.scss'

class SaidAboutUs extends PureComponent {
  componentWillMount() {
    this.props.dispatch(fetchLinks())
  }

  render() {
    const {
      loading,
      items,
      lng
    } = this.props

    return (
      <div className="saidAboutUs">
        {loading ? <LoadingOverlay /> : null}
        {
          items ? items.map(item => (
            <PressMessageCard
              lng={lng}
              key={item.Id}
              id={item.Id}
              dateTitle={lng.PressRoom.Publication}
              date={item.CreateDate}
              header={item.Title}
              content={item.Message}
              messageType={lng.PressRoom.SaidAboutUs}
              typeColor="#720A7B"
              externalLink={item.Url}
              showImage={item.HasImage}
            />
          )) : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.pressroom.saidaboutus.loading,
  items: state.pressroom.saidaboutus.items,
  lng: state.app.language
})

export default connect(mapStateToProps)(SaidAboutUs)