import initialState from './initialState'

const FETCH_MATERIALS = 'md/features/Materials/FETCH_MATERIALS'
const FETCH_MATERIALS_SUCCESS = 'md/features/Materials/FETCH_MATERIALS_SUCCESS'
const FETCH_MATERIALS_FAILED = 'md/features/Materials/FETCH_MATERIALS_FAILED'


export const fetchMaterials = () => {
  return {
    types: [FETCH_MATERIALS, FETCH_MATERIALS_SUCCESS, FETCH_MATERIALS_FAILED],
    callApi: {
      endpoint: 'api/materials/',
      method: 'GET'
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MATERIALS:
      return {
        ...state,
        loading: true,
        items: null
      }
    case FETCH_MATERIALS_SUCCESS:
      return {
        ...state,
        items: action.data,
        loading: false
      }
    case FETCH_MATERIALS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}