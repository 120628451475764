import initialState from './initialState'

const RESET_PASSWORD = 'md/features/User/ResetPassword/RESET_PASSWORD'
const RESET_PASSWORD_SUCCESS = 'md/features/User/ResetPassword/RESET_PASSWORD_SUCCESS'
const RESET_PASSWORD_FAILED = 'md/features/User/ResetPassword/RESET_PASSWORD_FAILED'

const TOGGLE_WINDOW = 'md/features/User/ResetPassword/TOGGLE_WINDOW'

export const toggleResetPassword = () => {
  return {
    type: TOGGLE_WINDOW
  }
}

export const resetPassword = (email) => {
  if (!email)
    throw new Error('Email is required')

  const payload = {
    email
  }

  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILED],
    callApi: {
      endpoint: 'api/ResetPassword',
      method: 'POST',
      data: payload
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_WINDOW:
      return {
        ...state,
        showWindow: !state.showWindow,
        passwordReset: false
      }
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordReset: true
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.message
      }
    default:
      return state
  }
}
