import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
//import { getWeekDate, weekInputCalc } from 'lib/utils'
import moment from 'moment'
import './style.scss'

class DefaultWeekInput extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,//.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,//.isRequired,
    onBlur: PropTypes.func,
    weeklabel: PropTypes.string,
    yearlabel: PropTypes.string
  }

  constructor(props) {
    super(props)
    
    this.state = {
      years: [],
      weeks: []
    }
  }

  onBlur = e => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e)
    }
  }

  componentDidMount() {
    const date = new Date()

    const year = date.getFullYear()
    let years = []
    years.push(year)
    years.push(year + 1)

    let weeks = []
    for (var i = 1; i <= moment().weeksInYear(); i++) {
      weeks.push(i)
    }

    this.setState({
      years,
      weeks
    })
  }

  render() {
    const {
      name,
      onChange,
      label,
      error,
      week,
      year,
      weeklabel,
      yearlabel
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    return (
      <div className="defaultWeekInput">
        {labelElement}
        <div>
          <span onClick={this.openSelect}>
            <label htmlFor="deliveryWeek">{weeklabel}:</label>
            <select className="week" id="fallbackWeek" name="deliveryWeekSelect" ref={this.setSelectRef} onChange={onChange}>
              <option value="" defaultValue>Välj</option>
              {
                this.state.weeks.map(w => (<option key={w} value={w} selected={w === week}>{w}</option>))
              }
            </select>
          </span>
          <span onClick={this.openSelect}>
            <label htmlFor="year">{yearlabel}:</label>
            <select className="year" id="year" name="deliveryYear" onChange={onChange}>
              <option value="" defaultValue>Välj</option>
              {
                this.state.years.map(y => (<option key={y} value={y} selected={y === year}>{y}</option>))
              }
            </select>
          </span>
        </div>
        {errorElement}
      </div>
    )
  }
}

export default DefaultWeekInput
