import initialState from './initialState'

const FETCH_GALLERY = 'md/features/Pressroom/pages/PressMedia/FETCH_GALLERY'
const FETCH_GALLERY_SUCCESS = 'md/features/Pressroom/pages/PressMedia/FETCH_GALLERY_SUCCESS'
const FETCH_GALLERY_FAILED = 'md/features/Pressroom/pages/PressMedia/FETCH_GALLERY_FAILED'

const SHOW_SLIDER = 'md/features/Pressroom/pages/PressMedia/SHOW_SLIDER'
const HIDE_SLIDER = 'md/features/Pressroom/pages/PressMedia/HIDE_SLIDER'

export const fetchGallery = () => {
  return {
    types: [FETCH_GALLERY, FETCH_GALLERY_SUCCESS, FETCH_GALLERY_FAILED],
    callApi: {
      endpoint: 'api/webimages/4',
      method: 'GET'
    }
  }
}

export const showSlider = (imageId) => {
  return {
    type: SHOW_SLIDER,
    imageId
  }
}

export const hideSlider = () => {
  return {
    type: HIDE_SLIDER
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GALLERY:
      return {
        ...state,
        loading: true
      }
    case FETCH_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_GALLERY_FAILED:
      return {
        ...state,
        loading: false
      }
    case SHOW_SLIDER:
      return {
        ...state,
        showSlider: true,
        sliderItem: action.imageId 
      }
    case HIDE_SLIDER:
      return {
        ...state,
        showSlider: false
      }
    default:
      return state
  }
}