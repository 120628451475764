import React, { PureComponent } from 'react'

import MoominProducts from './MoominProducts'
import MoominMessages from './MoominMessages'

class Moomin extends PureComponent {
  render() {
    return (
      <div className="moomin">
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <MoominMessages />
          </div>
          <div className="col-xs-12 col-sm-4">
            <MoominProducts />
          </div>
        </div>
      </div>
    )
  }
}

export default Moomin