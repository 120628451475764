import React, { Component } from 'react'
import { connect } from 'react-redux'
import DefaultInput from 'components/common/inputs/DefaultInput'
import Button from 'components/common/Button'
import LoadingOverlay from 'components/common/LoadingOverlay'

import {
  login
} from './duck'

import {
  toggleResetPassword
} from 'features/User/ResetPassword/duck'

import './style.scss'

export class LoginWidget extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: ''
    }
  }

  onChange = e => {
    const {
      name,
      value
    } = e.target

    this.setState({
      [name]: value
    })
  }

  onSubmit = e => {
    e.preventDefault()

    const {
      username,
      password
    } = this.state
    this.props.dispatch(login(username, password))
  }

  onToggleResetPassword = () => {
    this.props.dispatch(toggleResetPassword())
  }

  render() {
    const {
      lng,
      loading,
      invalidLogin
    } = this.props

    const {
      username,
      password
    } = this.state

    return (
      <form className="loginWidget" onSubmit={this.onSubmit}>
        {invalidLogin ? <div className="error">{lng.Common.LoginError}</div> : null}
        <DefaultInput type="text" placeholder={lng.Header.CustomerNumber} name="username" value={username} onChange={this.onChange} />
        <DefaultInput type="password" placeholder={lng.Header.PassWord} name="password" value={password} onChange={this.onChange} />
        <div className="buttonWrapper">
          <Button type="button" className="small" text={lng.Header.ResetPassword} onClick={this.onToggleResetPassword} />
          <Button type="submit" className="small" text={lng.Header.LogIn} />
        </div>
        {loading ? <LoadingOverlay /> : null}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.user.login.loading,
  invalidLogin: state.user.login.invalidLogin
})

export default connect(mapStateToProps)(LoginWidget)
