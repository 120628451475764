import initialState from './initialState'

const FETCH_ARTICLE = 'md/features/ArticleFeature/FETCH_ARTICLE'
const FETCH_ARTICLE_SUCCESS = 'md/features/ArticleFeature/FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FALIED = 'md/features/ArticleFeature/FETCH_ARTICLE_FALIED'


export const fetchArticle = (id) => {
  return {
    types: [FETCH_ARTICLE, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FALIED],
    callApi: {
      endpoint: 'api/article/' + id,
      method: 'GET'
    }
  }
}


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICLE:
      return {
        ...state,
        loading: true,
        data: null
      }
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case FETCH_ARTICLE_FALIED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}