import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import RawHTML from 'components/common/RawHTML'
import karinPic from 'images/karin_mini_headshot.jpg'

import './style.scss'

class PressContact extends PureComponent {
  render() {
    const {
      lng,
      webTexts
    } = this.props

    return (
      <div className="pressContact">
        <div className="row middle-sm">
          <div className="imageWrapper col-xs-12 col-sm-6">
            <img src={karinPic} alt="Karin" />
          </div>
          <div className="contactDetails col-xs-12 col-sm-6">
            <h4>{lng.PressRoom.Contact}</h4>
            <div>
              <RawHTML nl2br={true} content={webTexts.PressroomContact || ''}></RawHTML>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.app.language,
  webTexts: state.app.webTexts
})

export default connect(mapStateToProps)(PressContact)