import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import './style.scss'

export class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.state = { currentPage: 1 }
  }

  componentWillMount() {

    const pathing = this.props.location.pathname

    if (pathing === '/userprofile')
      this.setState({
        currentPage: 1
      })
    else if (pathing === '/deliveryaddresses')
      this.setState({
        currentPage: 2
      })
    else if (pathing === '/orders')
      this.setState({
        currentPage: 3
      })
    else if (pathing === '/invoices')
      this.setState({
        currentPage: 4
      })
  }

  navigate = (e) => {
    if (e.target.dataset.pageId)
      this.setState({
        currentPage: Number(e.target.dataset.pageId)
      })

    if (e.target.dataset.pageId === '1') {
      this.props.history.push('/userprofile')
    } else if (e.target.dataset.pageId === '2') {
      this.props.history.push('/deliveryaddresses')
    } else if (e.target.dataset.pageId === '3') {
      this.props.history.push('/orders')
    } else if (e.target.dataset.pageId === '4')
      this.props.history.push('/invoices')
  }

  render() {
    const {
      lng
    } = this.props

    return (
      <div className="productGroups">
        <ul>
          <li onClick={this.navigate}><span data-page-id="1" className={classNames({ active: this.state.currentPage === 1 })}>{lng.MyPage.MyDetails}</span></li>
          <li onClick={this.navigate}><span data-page-id="2" className={classNames({ active: this.state.currentPage === 2 })}>{lng.MyPage.DeliveryAddresses}</span></li>
          <li onClick={this.navigate}><span data-page-id="3" className={classNames({ active: this.state.currentPage === 3 })}>{lng.MyPage.Orders}</span></li>
          <li onClick={this.navigate}><span data-page-id="4" className={classNames({ active: this.state.currentPage === 4 })}>{lng.MyPage.Invoices}</span></li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  showmypage: state.app.showMyPage,
  lng: state.app.language
})


export default withRouter(connect(mapStateToProps)(UserMenu))
