import './style.scss'
import { ReactComponent as Logo } from 'images/logo_sign.svg'
import { ReactComponent as Instagram } from 'images/instagram.svg'
import { ReactComponent as LogoText } from 'images/text.svg'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Footer = () => {
  const lng = useSelector(state => state.app.language)

  return (
    <footer>
      <div className="content">
        <div>
          <LogoText />
          <div className="social-media">
            <a href="https://www.instagram.com/mittoditt/" target="_blank" rel="noreferrer">
              <Instagram />
            </a>
          </div>
        </div>
        <nav>
          <ul>
            <li><Link to="/koepvillkor">{lng.Menu.Terms}</Link></li>
            <li><Link to="/kontakt">{lng.Menu.Contact}</Link></li>
            <li><Link to="/pressroom">{lng.Menu.Pressroom}</Link></li>
          </ul>
        </nav>
        <div className="footer-logo">
          <Logo />
        </div>
      </div>
      <div className="copyright">
        © {new Date().getFullYear()} Mitt & Ditt
      </div>
    </footer>
  )
}

export default Footer