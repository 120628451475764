import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { format } from 'lib/utils'

import {
  toggleDueInvoices
} from './duck'

import FloatingWindow from 'components/containers/FloatingWindow'
import Button from 'components/common/Button'

import './style.scss'

export class DueInvoices extends PureComponent {
  closeWindow = () => {
    this.props.dispatch(toggleDueInvoices())
  }

  render() {
    const {
      lng,
      invoices,
      webtexts
    } = this.props

    return (
      <FloatingWindow
        showHeader={true}
        title={lng.Invoices.DueInvoices}
        draggable={false}
        closeOutSide={false}>
        <div className="dueInvoices">
          <div>
            {webtexts.DueInvoices}
          </div>
          <table className="default">
            <thead>
              <tr>
                <th>{lng.Invoices.InvoiceNumber}</th>
                <th>{lng.Invoices.DueDate}</th>
                <th className="right">{lng.Invoices.Balance}</th>
              </tr>
            </thead>
            <tbody>
              {
                invoices.map(invoice => (
                  <tr key={invoice.InvoiceNumber}>
                    <td>{invoice.InvoiceNumber}</td>
                    <td>{moment(invoice.DueDate).format('YYYY-MM-DD')}</td>
                    <td className="right">{format(invoice.Balance)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <div className="buttonWrap">
            <Button text={lng.Common.Close} type="button" onClick={this.closeWindow} />
          </div>
        </div>
      </FloatingWindow>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  invoices: state.dueinvoices.invoices,
  webtexts: state.app.webTexts,
})
export default connect(mapStateToProps)(DueInvoices)