import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import RawHTML from 'components/common/RawHTML'
import { fetchCategoryImages } from './duck'

const AboutUs = () => {
  const dispatch = useDispatch()
  const webTexts = useSelector(state => state.app.webTexts)
  const lng = useSelector(state => state.app.language)
  const images = useSelector(state => state.aboutus.images)

  useEffect(() => {
    dispatch(fetchCategoryImages(2))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // sort images by Sort property, then select the first 4
  const sortedImages = images?.sort((a, b) => a.Sort - b.Sort)?.slice(0, 4)
  const url = `${process.env.REACT_APP_API_URL}/api/WebImage/`

  return (
    <div className="page row">
      <div className="col-sm-6 xs-hide">
        <div className="imgContainer">
          {
            sortedImages ? sortedImages.map(image => (
              <img key={image.Id} src={`${url}/${image.Id}`} alt="" />
            )) : null
          }
        </div>
      </div>
      <div className="col-sm-6 text">
        <div className="col-xs-12 center-xs">
          <div className="logoContainer">
            <h3>{lng.StartPage.AboutUs}</h3>
          </div>
        </div>
        <div className="textContainer brodText col-xs-12">
          <h1>{lng.StartPage.AboutUsHeader}</h1>
          <RawHTML className="textCol" nl2br={false} content={webTexts.Aboutus || ''}></RawHTML>
        </div>
      </div>
    </div>
  )
}

export default AboutUs