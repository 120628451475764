import React, { Component } from 'react'
import classNames from 'classnames'
import { format, urlName } from 'lib/utils'

export class CartRow extends Component {
  onDelete = () => {
    this.props.onDelete(this.props.data.Id)
  }

  onAddQuantity = () => {
    const {
      Id,
      Quantity,
      QuantityPerPackage
    } = this.props.data
    const newQuantity = Quantity + QuantityPerPackage
    this.props.onQuantityChange(Id, newQuantity)
  }

  onRemoveQuantity = () => {
    const {
      Id,
      Quantity,
      QuantityPerPackage
    } = this.props.data
    const newQuantity = Quantity - QuantityPerPackage

    if (newQuantity <= 0) return

    this.props.onQuantityChange(Id, newQuantity)
  }

  render() {
    const {
      data
    } = this.props

    const canRemove = data.Quantity > data.QuantityPerPackage


    return (
      <tr>
        <td className="artnr"><a href={'/article/' + data.ArticleId + '/' + urlName(data.ArticleText)}>{data.ArticleNumber}</a></td>
        <td className="ben"><a href={'/article/' + data.ArticleId + '/' + urlName(data.ArticleText)}>{data.ArticleText}</a></td>
        <td className="antal">
          <span className="change addQuantity" onClick={this.onAddQuantity}>+</span>
          <span>{data.Quantity} st</span>
          <span className={classNames('change', { removeQuantity: canRemove, disabledQuantity: !canRemove })} onClick={this.onRemoveQuantity}>-</span>
        </td>
        <td className="pris">{format(data.Price)}</td>
        <td className="summa">{format(data.Price * data.Quantity)}</td>
        <td className="radera"><div className="remove" onClick={this.onDelete}>Radera</div></td>
      </tr>
    )
  }
}

export default CartRow
