import React, { Component } from 'react'
import { connect } from 'react-redux'
import { format } from 'lib/utils'
import moment from 'moment'
import orderStatus from 'lib/enums/orderStatus'

import Button from 'components/common/Button'
import OrderRowsTable from './OrderRowsTable'

export class OrdersBody extends Component {
  getOrderRows = (orderId) => {
    const {
      orderRows
    } = this.props

    const rows = orderRows && orderRows[orderId]
    return rows || []
  }

  getCreateDate = (order) => {
    return moment(order.CreateDate).format('YYYY-MM-DD')
  }

  onShowRows = (orderId) => {
    this.props.onShowRows(orderId)
  }

  isDelivered = (order) => {
    return order.Status === orderStatus.Delivered || order.Status === orderStatus.Invoiced
  }

  getOrderStatus = order => {
    const {
      lng
    } = this.props
    const isDelivered = order.Status === orderStatus.Delivered || order.Status === orderStatus.Invoiced
    if (isDelivered) {
      return lng.Orders.Sent
    } else {
      return lng.Orders.Order
    }
  }

  getTrackingText = order => {
    const trackingUrl = `https://activetracing.dhl.com/DatPublic/search.do?search=consignmentId&autoSearch=true&l=sv&at=consignment&a=${order.ConsignmentId}`
    return order.ConsignmentId ? (<a href={trackingUrl} className="tracking" target="_blank" rel="noreferrer">Spåra</a>) : null
  }

  render() {
    const {
      loading,
      orderdata,
      viewOrderRows,
      lng
    } = this.props

    let noRows = null
    if (orderdata && orderdata.Orders.length === 0 && !loading) {
      noRows = (
        <tbody>
          <tr>
            <td colSpan="10" className="noRows">{lng.Common.NoRowsFound}</td>
          </tr>
        </tbody>
      )
    }

    return (
      orderdata && orderdata.Orders.length ? orderdata.Orders.map(order => {
        return (
          <tbody key={order.Id}>
            <tr>
              <td>{order.Id}</td>
              <td>{this.getCreateDate(order)}</td>
              <td>{this.getOrderStatus(order)}{this.getTrackingText(order)}</td>
              <td>{order.WayOfDeliveryName}</td>
              <td className="right">{format(order.AmountExludeVat)}</td>
              <td className="right">{format(order.VatAmount)}</td>
              <td className="right">{format(order.TotalAmountIncludeVat)}</td>
              <td><Button type="button" className="tiny" text={lng.Common.ViewRows} onClick={this.onShowRows} meta={order.Id} /></td>
            </tr>
            {
              viewOrderRows.indexOf(order.Id) !== -1 ?
                <OrderRowsTable lng={lng} rows={this.getOrderRows(order.Id)} /> : null
            }
          </tbody>
        )
      }) : noRows
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.mypage.orders.loading,
  orderdata: state.mypage.orders.data,
  orderRows: state.mypage.orders.orderRows,
  viewOrderRows: state.mypage.orders.viewOrderRows,
  lng: state.app.language
})

export default connect(mapStateToProps)(OrdersBody)
