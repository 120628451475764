import initialState from './initialState'
import {
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const FETCH_INVOICES = 'md/features/MyPage/Invoices/FETCH_INVOICES'
const FETCH_INVOICES_SUCCESS = 'md/features/MyPage/Invoices/FETCH_INVOICES_SUCCESS'
const FETCH_INVOICES_FAILED = 'md/features/MyPage/Invoices/FETCH_INVOICES_FAILED'

//Actions
export const fetchInvoices = (page) => {
  const takeCount = 30
  const payload = {
    skip: takeCount * (page - 1),
    take: 30
  }

  return {
    types: [FETCH_INVOICES, FETCH_INVOICES_SUCCESS, FETCH_INVOICES_FAILED],
    callApi: {
      endpoint: 'api/Invoices',
      method: 'GET',
      data: payload
    },
    page,
    take: payload.take
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
      return {
        ...state,
        data: null,
        currentPage: 1
      }
    case FETCH_INVOICES:
      return {
        ...state,
        loading: true,
        currentPage: action.page,
        take: action.take
      }
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case FETCH_INVOICES_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}