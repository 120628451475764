import React from 'react'
import { useSelector } from 'react-redux'
import logoTransparent_beige from 'images/logo_sign_transparent_beige.png'
import './style.scss'
import RawHTML from 'components/common/RawHTML'

import Karin_Westin from 'images/workers/Karin_Westin.png'
import Linda_Johansson from 'images/workers/Linda_Johansson.png'
import Ola_Alexandersson from 'images/workers/Ola_Alexandersson.png'
import Jennifer_Lindberg from 'images/workers/Jennifer_Lindberg.png'
import Sofie_Häggkvist from 'images/workers/Sofie_Häggkvist.png'

const Contact = () => {
  const lng = useSelector(state => state.app.language)
  const webtexts = useSelector(state => state.app.webTexts)

  const workers = [
    {
      name: 'Karin Westin VD',
      image: Karin_Westin,
      text: '',
      email: 'karin@mittditt.se',
      phone: '070-2695551'
    },
    {
      name: 'Linda Johansson',
      image: Linda_Johansson,
      text: 'Design & säljare södra Sverige',
      email: 'linda@mittditt.se',
      phone: '070-9223223'
    },
    {
      name: 'Ola Alexandersson',
      image: Ola_Alexandersson,
      text: 'Säljare Norra Sverige',
      email: 'ola@mittditt.se',
      phone: '070-2600627'
    },
    {
      name: 'Jennifer Lindberg',
      image: Jennifer_Lindberg,
      text: 'Kund/ Ordermottagning',
      email: 'jennifer@mittditt.se',
      phone: '0660-220025'
    },
    {
      name: 'Sofie Häggqvist',
      image: Sofie_Häggkvist,
      text: 'Lager',
      email: 'sofie@mittditt.se',
      phone: '0660-220025'
    }
  ]

  return (
    <div className="contactPage">
      <div>
        <div className="logoContainer">
          <img src={logoTransparent_beige} alt="logo" /><h3>{lng.Menu.Contact}</h3>
        </div>
      </div>
      <div className="contactContainer">
        <div className="webTextContainer">
          <RawHTML nl2br={true} content={webtexts.Contact || ''}></RawHTML>
        </div>
      </div>
      <div className="workersContainer">
        {workers.map((worker, index) => (
          <div className="worker" key={index}>
            <div className="workerImage">
              <img src={worker.image} alt={worker.name} />
            </div>
            <div className="workerText">
              <h4 className="workerName">{worker.name}</h4>
              {worker.text && (<div>{worker.text}</div>)}
              {worker.email && (<div>{lng.Common.Email}: <a href={`mailto:${worker.email}`}>{worker.email}</a></div>)}
              {worker.phone && (<div>{lng.CustomerDetails.Telephone}: <a href={`tel:${worker.phone}`}>{worker.phone}</a></div>)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Contact