import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import LoadingOverlay from 'components/common/LoadingOverlay'
import PressMessageCard from 'components/common/PressMessageCard'

import {
  fetchMessages
} from './duck'

import './style.scss'

class MoominMessage extends PureComponent {

  fetchMessages = (languageCode) => {
    this.props.dispatch(fetchMessages(1, languageCode))
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentLanguage !== nextProps.currentLanguage)
      this.fetchMessages(nextProps.currentLanguage)
  }

  componentWillMount = () => {
    this.fetchMessages(this.props.currentLanguage)
  }

  render() {
    const {
      loading,
      messages,
      lng
    } = this.props

    return (
      <div className="moominMessages">
        {loading ? <LoadingOverlay /> : null}
        {
          messages ? messages.map(message => (
            <PressMessageCard
              lng={lng}
              key={message.Id}
              id={message.Id}
              date={message.CreateDate}
              header={message.Title}
              content={message.Message}
              showImage={message.HasImage}
              messageType={lng.PressRoom.Pressmessage}
            />
          )) : null
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.pressroom.moomin.messages.loading,
  messages: state.pressroom.moomin.messages.items,
  currentLanguage: state.app.currentLanguage,
  lng: state.app.language
})

export default connect(mapStateToProps)(MoominMessage)