import initialState from './initialState'
import {
  getCookie,
  setCookie
} from 'lib/utils'


const TOGGLE_COOKIES = 'md/features/CookieFeature/TOGGLE_COOKIES'
const CHECKED_COOKIE = 'md/features/CookieFeature/CHECKED_COOKIE'
const ACCEPT_COOKIE = 'md/features/CookieFeature/ACCEPT_COOKIE'

//Actions
export const checkedCookie = accepted => {
  accepted = accepted || false
  return {
    type: CHECKED_COOKIE,
    accepted
  }
}

export const setAccepted = () => {
  return {
    type: ACCEPT_COOKIE
  }
}

export const toggleCookies = () => {
  return {
    type: TOGGLE_COOKIES
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COOKIES:
      return {
        ...state,
        showCookies: !state.showCookies
      }
    case CHECKED_COOKIE:
      return {
        ...state,
        checked: true,
        accepted: action.accepted
      }
    case ACCEPT_COOKIE:
      return {
        ...state,
        accepted: true
      }
    default:
      return state
  }
}

//Commands
export const checkCookie = () => {
  const accepted = getCookie('acceptedCookies')
  const isAccepted = accepted !== '' && accepted !== undefined && accepted !== null && accepted !== false

  return dispatch => dispatch(checkedCookie(isAccepted))
}

export const acceptedCookie = () => {
  setCookie('acceptedCookies', true, 60)
  return dispatch => dispatch(setAccepted())
}