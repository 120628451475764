import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  authenticated: isAuthenticated,
  initialCheck: isChecked,
  public: isPublic,
  ...rest
}) => (

  isChecked || isPublic ?
    <Route {...rest} render={props => (
      isAuthenticated || isPublic ? (
        <Layout component={Component} {...props} />)
        :
        <Redirect to={{ pathname: '/requirelogin', state: { from: props.location } }} />
    )} />
    :
    null
)

export default PrivateRoute