import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import LoadingSpinner from 'components/common/LoadingSpinner'
import RawHTML from 'components/common/RawHTML'

import {
  fetchMessage
} from './duck'

import './style.scss'

class PressMessage extends PureComponent {
  fetchMessage = (languageCode) => {
    const messageId = this.props.match.params.id
    this.props.dispatch(fetchMessage(messageId, this.props.currentLanguage))
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentLanguage !== nextProps.currentLanguage)
      this.fetchMessage(nextProps.currentLanguage)
  }

  componentWillMount = () => {
    this.fetchMessage(this.props.currentLanguage)
  }

  render() {
    const {
      loading,
      message
    } = this.props

    return (
      <div className="pressMessage">
        {loading ? <LoadingSpinner /> : null}
        {message ? <div className="pressMessageContainer">
          <div className="date">{moment(message.CreateDate).format('D MMMM YYYY')}</div>
          <div className="header">
            <h2>{message.Title}</h2>
          </div>
          <div className="content">
            <RawHTML nl2br={true} content={message.Message || ''}></RawHTML>
          </div>
        </div>
          : null
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.pressroom.message.loading,
  message: state.pressroom.message.item,
  currentLanguage: state.app.currentLanguage
})

export default connect(mapStateToProps)(PressMessage)