import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.scss'

export class DefaultSelect extends PureComponent {
  static propTypes = {
    items: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    arrowStyle: PropTypes.bool,
    required: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      items: null
    }
  }

  static defaultProps = {
    arrowStyle: true
  }

  onBlur = e => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(e)
    }
  }

  render() {
    const {
      children,
      items,
      name,
      value,
      onChange,
      label,
      error,
      disabled,
      arrowStyle,
      defaultValue
    } = this.props

    const labelElement = label ? (
      <label htmlFor={name}>{label}</label>
    ) : null

    const errorElement = error ? (
      <div className="errormsg">{error}</div>
    ) : null

    return (
      <div className={arrowStyle ? 'defaultSelect' : 'defaultSelectNoArrow'}>
        {labelElement}
        <div className="button"></div>
        <select
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={classNames({ error: error })}
          disabled={disabled}
          onBlur={this.onBlur}>
          {
            children ? children : items.map(item => <option key={item.key} value={item.value}>{item.value}</option>)
          }
        </select>
        {errorElement}
      </div>
    )
  }
}

export default DefaultSelect
