import React, { Component } from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import logoTransparent_beige from 'images/logo_sign_transparent_beige.png'
import RawHTML from 'components/common/RawHTML'
import './style.scss'

export class Terms extends Component {
  //static propTypes = {
  //  prop: PropTypes
  //}

  render() {
    return (
      <div className="termsPage">
        <div>
          <div className="logoContainer">
            <img src={logoTransparent_beige} alt="logo" /><h3>{this.props.lng.StartPage.Terms}</h3>
          </div>
        </div>
        <div className="textContainer brodText">
          <h1>Detta gäller när du handlar av oss</h1>
          <RawHTML nl2br={true} content={this.props.webtexts.Terms || ''}></RawHTML>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  webtexts: state.app.webTexts,
  lng: state.app.language
})

export default connect(mapStateToProps)(Terms)
