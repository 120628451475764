import React from 'react'
import PropTypes from 'prop-types'
import './ImageGridSlider.scss'
import classNames from 'classnames'

const ImageGridSlider = ({
  images,
  endpoint
}) => {
  const [imageGroups, setImageGroups] = React.useState([])
  const [currentImageGroup, setCurrentImageGroup] = React.useState(0)
  const [height, setHeight] = React.useState(null)
  const ref = React.useRef(null)

  const calculateHeight = () => {
    if (ref.current) {
      const bounding = ref.current.getBoundingClientRect()
      const height = bounding.width / 2
      setHeight(height)
    }
  }

  // from the images array, create an array of arrays of images, 3 images in each array, it the last array doesnt add upp to 3, duplicate the last image
  React.useEffect(() => {
    const imageGroups = []
    let imageGroup = []
    images.forEach((image, index) => {
      if (imageGroup.length === 3) {
        imageGroups.push(imageGroup)
        imageGroup = []
      }
      imageGroup.push(image)
      if (index === (images.length - 1)) {
        if (imageGroup.length < 3) {
          const lastImage = imageGroup[imageGroup.length - 1]
          while (imageGroup.length < 3) {
            imageGroup.push(lastImage)
          }
        }
        imageGroups.push(imageGroup)
      }
    })
    setImageGroups(imageGroups)
  }, [images])

  // switch group every 4 seconds
  React.useEffect(() => {
    const timer = setInterval(() => {
      let nextGroup
      if (currentImageGroup === (imageGroups.length - 1))
        nextGroup = 0
      else
        nextGroup = currentImageGroup + 1
      setCurrentImageGroup(nextGroup)
    }, 4000)
    return () => {
      clearInterval(timer)
    }
  }, [currentImageGroup, imageGroups])

  // on mount, calculate height
  React.useEffect(() => {
    calculateHeight()
  }, [])

  // on resize, recalculate height
  React.useEffect(() => {
    window.addEventListener('resize', calculateHeight)
    return () => {
      window.removeEventListener('resize', calculateHeight)
    }
  }, [])

  const containerStyle = {
    height: height + 'px'
  }

  return (
    <div className="ImageGridSlider" ref={ref} style={containerStyle}>
      {imageGroups && imageGroups.map((imageGroup, index) => (
        <div key={index} className={classNames('group', { show: index === currentImageGroup })}>
          {
            imageGroup && imageGroup.map((image, index) => {
              return (
                <div key={index} className="ImageGridSlider__image">
                  <img src={`${endpoint}${image.Id}`} alt={image.name} />
                </div>
              )
            })
          }
        </div>
      ))}
    </div>
  )
}

ImageGridSlider.propTypes = {
  images: PropTypes.array.isRequired,
  endpoint: PropTypes.string.isRequired
}

export default ImageGridSlider