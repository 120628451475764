import { setShowOnlyStock } from 'features/Products/duck'
import SearchWidget from 'features/SearchWidget'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.scss'
import { useLocalstorage } from 'hooks/useLocalstorage'

const SearchFeatures = () => {
  const dispatch = useDispatch()
  const { hasLocalstorage } = useLocalstorage()

  const onlyStockArticles = useSelector(state => state.products?.onlyStockArticles)
  const lng = useSelector(state => state.app.language)

  const showOnlyStock = (e) => {
    let showOnlyStock = e.target.checked

    if (hasLocalstorage) {
      localStorage.setItem('showOnlyStockItems', showOnlyStock)
    }

    dispatch(setShowOnlyStock(showOnlyStock))
  }

  return (
    <div className="SearchFeatures">
      <div className="onlyStockArea">
        <label>
          <input
            type="checkbox"
            id="onlystokitems"
            onClick={showOnlyStock}
            value="true"
            checked={onlyStockArticles}
          />
          {lng.Search.ShowOnlyStockItems}
        </label>
      </div>
      {
        <SearchWidget />
      }
    </div>
  )
}

export default SearchFeatures