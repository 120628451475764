import {
  combineReducers
} from 'redux'

import login from '../LoginWidget/duck'
import setnewpassword from '../SetNewPassword/duck'
import resetpassword from '../ResetPassword/duck'

const reducer = combineReducers({
  login,
  setnewpassword,
  resetpassword
})

export default reducer