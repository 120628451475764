import initialState from './initialState'

const UPDATE_PASSWORD = 'md/features/MyPage/UserProfile/UPDATE_PASSWORD'
const UPDATE_PASSWORD_SUCCESS = 'md/features/MyPage/UserProfile/UPDATE_PASSWORD_SUCCESS'
const UPDATE_PASSWORD_FAILED = 'md/features/MyPage/UserProfile/UPDATE_PASSWORD_FAILED'

const CHANGE_PASSWORD_FORM = 'md/features/MyPage/UserProfile/CHANGE_PASSWORD_FORM'

export const updatePassword = (currentPW, newPW) => {
  const payload = {
    CurrentPassword: currentPW,
    NewPassword: newPW
  }

  return {
    types: [UPDATE_PASSWORD, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILED],
    callApi: {
      endpoint: 'api/customer',
      method: 'PUT',
      data: payload
    }
  }
}

export const passwordFormChanged = (name, value) => {
  return {
    type: CHANGE_PASSWORD_FORM,
    name,
    value
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return {
        ...state,
        loading: true
      }
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordForm: {
          CurrentPassword: '',
          NewPassword: '',
          RepeatNewPassword: '',
        }
      }
    case UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    case CHANGE_PASSWORD_FORM:
      return {
        ...state,
        passwordForm: {
          ...state.passwordForm,
          [action.name]: action.value
        }
      }
    default:
      return state
  }
}