import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  pageHistory
} from 'features/App/duck'

import Intro from 'features/Intro'
import AboutUs from 'features/AboutUs'
import Resellers from 'features/Resellers'

export class StartPage extends Component {

  onSectionChanged = (key) => {
    this.props.dispatch(pageHistory(key))
  }

  render() {
    return (
      <div>
        <Intro />
        <AboutUs />
        <Resellers />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.app.currentPage
})

export default connect(mapStateToProps)(StartPage)
