import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { urlName } from 'lib/utils'
import { fetchProducts } from './duck'
import noPicture from 'images/nopic.jpg'

import './style.scss'

class MoominProducts extends PureComponent {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props)
  }

  readMore = (product) => {
    const url = `/pressroom/product/${product.Id}/${urlName(product.ArticleText)}`
    this.context.router.history.push(url)
  }

  componentWillMount = () => {
    const {
      loading,
      items
    } = this.props

    if (!loading && !items)
      this.props.dispatch(fetchProducts())
  }

  render() {
    const {
      items
    } = this.props

    return (
      <div className="moominProducts">
        <h2>Produkter</h2>
        <div className="pressProduct">
          {
            items ? items.map(product => {
              const imageUrl = product.ImageId ? `${process.env.REACT_APP_API_URL}/api/ArticleThumbnail/${product.ImageId}` : noPicture

              return (
                <div className="product" key={product.Id} onClick={() => this.readMore(product)}>
                  <img src={imageUrl} alt={product.ArticleText} />
                  <div className="readMore">
                    Läs mer<div className="arrow"><div className="outline" /></div>
                  </div>
                </div>
              )
            }) : null
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.pressroom.moomin.products.loading,
  items: state.pressroom.moomin.products.items
})

export default connect(mapStateToProps)(MoominProducts)