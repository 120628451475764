import Footer from 'features/App/Footer/Footer'
import Header from 'features/App/Header'
import Navigation from 'features/App/Navigation'
import SubNavigation from 'features/App/SubNavigation/SubNavigation'
import React from 'react'

const ShopLayout = ({
  component: Component,
  ...rest
}) => {
  return (
    <>
      <div className="headnav">
        <Header />
        <Navigation />
      </div>
      <SubNavigation />
      <main>
        <Component {...rest} />
      </main>
      <Footer />
    </>
  )
}

export default ShopLayout