import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import 'lib/polyfills'
import store from './redux/store'
import 'css/base.scss'
import 'normalize.css'
import App from 'features/App'
import {
  // register as registerServiceWorker,
  unregister
} from './registerServiceWorker'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://20b79a6549644dd19e1f5b29c33c9c04@o4503988312145920.ingest.sentry.io/4504701190537216',
    integrations: [new BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

//registerServiceWorker()
unregister()

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!')
  console.log('API: ' + process.env.REACT_APP_API_URL)
}