import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import './style.scss'

import LoggedIn from './LoggedIn'
import LoginWidget from 'features/User/LoginWidget'
import MittDittLogo from 'images/logo_text.png'

import {
  testLocalstorage,
  checkPathName
} from 'lib/utils'

import {
  setShowOnlyStock
} from 'features/Products/duck'

import {
  toggleMyPage,
} from 'features/App/duck'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasLocalstorage: testLocalstorage()
    }
  }

  showOnlyStock = (e) => {
    let showOnlyStock = e.target.checked

    if (this.state.hasLocalstorage) {
      localStorage.setItem('showOnlyStockItems', showOnlyStock)
    }

    this.props.dispatch(setShowOnlyStock(showOnlyStock))

  }

  toWebShop = () => {
    this.props.dispatch(toggleMyPage(false))
    this.props.history.push('/products')
  }

  shouldHide = () => {
    const {
      location
    } = this.props
    const currentPage = location.pathname
    const hideForPages = ['/start']

    const foundPage = hideForPages.find(path => currentPage.indexOf(path) === 0)
    return foundPage || currentPage === '/'
  }

  componentWillMount() {
    if (this.state.hasLocalstorage) {
      const showOnlyStock = localStorage.getItem('showOnlyStockItems') === 'true'
      this.props.dispatch(setShowOnlyStock(showOnlyStock))
    }

    let checkedPath = checkPathName(this.props.location.pathname)
    this.props.dispatch(toggleMyPage(checkedPath))
  }

  render() {
    const {
      login
    } = this.props

    const loginWrapper = login.loggedIn ? <LoggedIn /> : <LoginWidget />

    return (
      <div>
        <header className='Header'>
          <div></div>
          <div className="headerLogo">
            <img src={MittDittLogo} alt="Mitt & Ditt" />
          </div>
          <div className="cartInfoWidget">
            {login.initialCheck ? loginWrapper : null}
          </div>
        </header>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    login: state.user.login,
    lng: state.app.language,
    onlyStockArticles: state.products.onlyStockArticles,
    showmypage: state.app.showMyPage
  }
}

export default withRouter(connect(mapStateToProps)(Header))