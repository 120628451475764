import initialState from './initialState'

const FETCH_RESELLERS = 'md/features/resellers/FETCH_RESELLERS'
const FETCH_RESELLERS_SUCCESS = 'md/features/resellers/FETCH_RESELLERS_SUCCESS'
const FETCH_RESELLERS_FAILED = 'md/features/resellers/FETCH_RESELLERS_FAILED'

const SEND_RESELLER_APPLICATION = 'md/features/resellers/SEND_RESELLER_APPLICATION'
const SEND_RESELLER_APPLICATION_SUCCESS = 'md/features/resellers/SEND_RESELLER_APPLICATION_SUCCESS'
const SEND_RESELLER_APPLICATION_FAILED = 'md/features/resellers/SEND_RESELLER_APPLICATION_FAILED'

const TOGGLE_FORM = 'md/features/resellers/TOGGLE_FORM'
const RESET_STATE = 'md/features/resellers/RESET_STATE'
// const RESELLER_FORM_ERROR = 'md/features/resellers/RESELLER_FORM_ERROR'

//Actions
export const fetchResellers = () => {
  return {
    types: [FETCH_RESELLERS, FETCH_RESELLERS_SUCCESS, FETCH_RESELLERS_FAILED],
    callApi: {
      endpoint: 'api/resellers',
      method: 'GET'
    }
  }
}

export const sendResellerApplication = (payload) => {
  return {
    types: [SEND_RESELLER_APPLICATION, SEND_RESELLER_APPLICATION_SUCCESS, SEND_RESELLER_APPLICATION_FAILED],
    callApi: {
      endpoint: 'api/resellerapplication',
      method: 'POST',
      data: payload
    }
  }
}

export const resetState = () => {
  return {
    type: RESET_STATE
  }
}


export const toggleForm = () => {
  return {
    type: TOGGLE_FORM
  }
}

// export const resellersFormError = (name, value) => {
//   return {
//     type: RESELLER_FORM_ERROR,
//     name,
//     value
//   }
// }

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESELLERS:
      return {
        ...state,
        loading: true
      }
    case FETCH_RESELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_RESELLERS_FAILED:
      return {
        ...state,
        loading: false
      }
    case SEND_RESELLER_APPLICATION:
      return {
        ...state,
        loading: true,
        applicationSent: false
      }
    case SEND_RESELLER_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        //response:action.data,
        applicationSent: true
      }
    case SEND_RESELLER_APPLICATION_FAILED:
      return {
        ...state,
        loading: false,
        applicationSent: false
      }
    case TOGGLE_FORM:
      return {
        ...state,
        showForm: !state.showForm
      }
    case RESET_STATE:
      return {
        ...state,
        applicationSent: false
      }
    // case RESELLER_FORM_ERROR:
    //   return {
    //     ...state,
    //     resellerErrors: {
    //       ...state.resellerErrors,
    //       [action.name]: action.value
    //     }
    //   }
    default:
      return state
  }
}