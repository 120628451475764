import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'components/common/Button'

import {
  toggleCookies,
  acceptedCookie
} from './duck'
import './style.scss'

class CookieFeature extends Component {
  acceptCookie = () => {
    this.props.dispatch(acceptedCookie())
  }

  toggleCookies = () => {
    this.props.dispatch(toggleCookies())
  }

  render() {
    const {
      lng
    } = this.props

    return (
      <div className="cookieFeature">
        <div className="cookieFeatureContainer">
          <span>{lng.Information ? lng.Information.CookieText : ''}</span>
          <span className="cookieLink" onClick={this.toggleCookies}>cookies</span>
          <Button type="button" className="cookieAcceptButton" text={lng.Common.Accept} onClick={this.acceptCookie}></Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  showCookies: state.cookies.showCookies
})

export default connect(mapStateToProps)(CookieFeature)
