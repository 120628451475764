export default {
  name: {
    required: true,
    minLength: 2
  },
  company: {
    required : true,
    minLength: 2
  },
  email: {
    required: true,
    email: true
  },
  identity: {
    required: true,
    minLength: 2
  },
  address: {
    required: true,
    minLength: 2
  },
  zipCodeAndCity: {
    required: true
  },
  telephone: {
    required: true,
    number: true
  }
}
