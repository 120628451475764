import React, { PureComponent } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'

import './style.scss'

export class Pagination extends PureComponent {
  static propTypes = {
    totalCount: propTypes.number.isRequired,
    currentItem: propTypes.number.isRequired,
    take: propTypes.number.isRequired,
    onChange: propTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      showBack: false,
      showForward: false,
      items: []
    }
  }

  updateButtons = (props) => {
    const showBack = props.currentItem !== 1 && this.state.items.length > 2
    const showForward = props.currentItem !== this.state.items.length && this.state.items.length > 2

    this.setState({
      showBack,
      showForward
    })
  }

  generateNavigation = (props) => {
    const pages = Math.ceil(props.totalCount / props.take)
    let items = []
    for (let i = 1; i <= pages; i++) {
      items.push(i)
    }

    this.setState({
      items
    })
  }

  onBack = () => {
    this.props.onChange(this.props.currentItem - 1)
  }

  onForward = () => {
    this.props.onChange(this.props.currentItem + 1)
  }

  onClick = (page) => {
    this.props.onChange(page)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.totalCount !== this.props.totalCount) {
      this.generateNavigation(nextProps)
    }

    this.updateButtons(nextProps)
  }

  componentWillMount() {
    this.generateNavigation(this.props)
  }

  render() {
    const {
      currentItem
    } = this.props

    if (!this.state.items || this.state.items.length <= 1)
      return null

    return (
      <div className="pagination">
        {this.state.showBack ? <div onClick={this.onBack} className="arrow">‹</div> : null}
        {this.state.items.map(item => (<div key={item} onClick={() => this.onClick(item)} className={classNames({ active: currentItem === item })}>{item}</div>))}
        {this.state.showForward ? <div onClick={this.onForward} className="arrow">›</div> : null}
      </div>
    )
  }
}

export default Pagination