export default {
  deliveryAddresses: null,
  deliveryAddress: {
    Name: '',
    Address1: '',
    Address2: '',
    ZipCode: '',
    City: '',
    CountryCode: '',
    CountryName: '',
    ContactPerson: '',
    DefaultAddress: false
  },
  loading: false,
  showForm: false,
  addressId: null,
  toggleReload: false
}