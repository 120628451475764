import initialState from './inintialState'

const FETCH_CATEGORYIMAGES = 'md/features/aboutus/FETCH_CATEGORYIMAGES'
const FETCH_CATEGORYIMAGES_SUCCSESS = 'md/features/aboutus/FETCH_CATEGORYIMAGES_SUCCSESS'
const FETCH_CATEGORYIMAGES_FAILED = 'md/features/aboutus/FETCH_CATEGORYIMAGES_FAILED'


//Actions
export const fetchCategoryImages = id => {
  return {
    types: [FETCH_CATEGORYIMAGES, FETCH_CATEGORYIMAGES_SUCCSESS, FETCH_CATEGORYIMAGES_FAILED],
    callApi: {
      endpoint: 'api/webimages/' + id,
      method: 'GET',
    }
  }
}


//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORYIMAGES:
      return {
        ...state,
        loading: true
      }
    case FETCH_CATEGORYIMAGES_SUCCSESS:
      return {
        ...state,
        loading: false,
        images: action.data
      }
    case FETCH_CATEGORYIMAGES_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}