import React, { Component } from 'react'
import CartRow from '../CartRow'
import { format } from 'lib/utils'

export class CartBody extends Component {
  render() {
    const {
      data,
      lng
    } = this.props

    return (
      <tbody>
        {
          data.Rows.map(row =>
            <CartRow
              key={row.Id}
              data={row}
              onQuantityChange={this.props.onQuantityChange}
              onDelete={this.props.onDelete}
              lng={lng} />
          )
        }
        <tr className="summary">
          <td colSpan="4" className="summatext">{lng.Cart.SumExcludeVat}</td>
          <td className="summa">{format(data.TotalExcludingVat)}</td>
          <td className="radera"></td>
        </tr>
        <tr className="summary">
          <td colSpan="4" className="summatext">{lng.Common.Vat}</td>
          <td className="summa">{format(data.VatAmount)}</td>
          <td className="radera"></td>
        </tr>
        <tr className="summary">
          <td colSpan="4" className="summatext">{lng.Cart.SumIncVat}</td>
          <td className="summa">{format(data.TotalAmount)}</td>
          <td className="radera"></td>
        </tr>
      </tbody>
    )
  }
}

export default CartBody
