import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchResellers
} from './duck'
import Button from 'components/common/Button'
import ResellerCountry from './ResellerCountry'

import './style.scss'

const Resellers = () => {
  const dispatch = useDispatch()
  const items = useSelector(state => state.resellers.items)
  const lng = useSelector(state => state.app.language)  

  const toggleForm = () => {
    // route to /application
    window.location.href = '/application'
  }

  useEffect(() => {
    dispatch(fetchResellers())
  }, [dispatch])

  return (
    <div className="resellerPage row">
      <div className="buttonContainer">
        <Button className="applyButton" type="button" text={lng.Reseller.Application} onClick={toggleForm}></Button>
      </div>
      {
        items ? items.map(country => (<ResellerCountry key={country.CountryCode} country={country} />)) : null
      }
    </div>
  )
}

export default Resellers