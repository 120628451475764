import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import multicolor from 'images/multicolor.png'

import './style.scss'

class ColorCheckbox extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    hex: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired
  }

  onClick = () => {
    const {
      id,
      checked
    } = this.props

    this.props.onClick(id, !checked)
  }

  render() {
    const {
      id,
      hex,
      name,
      checked
    } = this.props

    let style = {}
    if (hex) {
      style.backgroundColor = hex
    } else {
      style.backgroundImage = `url(${multicolor})`
    }

    //// <div class="checkbox long"><input type="checkbox" id="color-'.$row['Id'].'" name="ColorId[]" value="'.$row['Id'].'" /><label for="color-'.$row['Id']. '"><span class="circle ' . $row['Text'] . '" style="'.$bg.';" title="' . $colortext . '"></span>'.$colortext.'</label></div>
    return (
      <div className="colorCheckbox" title={name} onClick={this.onClick}>
        <input type="checkbox" value={id} name={`color-${id}`} checked={checked} />
        <label htmlFor={`color-${id}`} style={style} onClick={this.onChange}></label>{!hex ? <span>{name}</span> : ''}
      </div>
    )
  }
}

export default ColorCheckbox