import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  fetchCart
} from 'features/ShoppingCart/duck'
import {
  format
} from 'lib/utils'
import Button from 'components/common/Button'
import './style.scss'

export class CartWidget extends Component {
  componentDidMount() {
    if (!this.props.data && !this.props.loading) {
      this.props.dispatch(fetchCart())
    }
  }
  navigate = () => {
    this.props.history.push('/cart')
  }

  render() {
    const {
      lng,
      data
    } = this.props

    if (!data) return null

    return (
      <div className="cartWidget">
        <div>{lng.Header.ItemAmount} {data.Rows.length} st</div>
        <div>{lng.Common.Amount} {format(data.TotalExcludingVat)} :-</div>
        <Button type="button" className="button" onClick={this.navigate} text={lng.Header.GoToCheckOut}></Button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.shoppingcart.loading,
  data: state.shoppingcart.data
})

export default withRouter(connect(mapStateToProps)(CartWidget))
