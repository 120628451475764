import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import RawHTML from 'components/common/RawHTML'

import { urlName } from 'lib/utils'

import './style.scss'

class PressMessageCard extends PureComponent {
  static propTypes = {
    date: PropTypes.string.isRequired,
    dateTitle: PropTypes.string,
    header: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    messageType: PropTypes.string.isRequired,
    externalLink: PropTypes.string,
    showImage: PropTypes.bool,
    lng: PropTypes.object
  }

  render() {
    const {
      id,
      date,
      dateTitle,
      header,
      content,
      messageType,
      externalLink,
      showImage,
      typeColor,
      lng
    } = this.props

    const readMoreUrl = `/pressroom/press/${id}/${urlName(header)}`
    const imageUrl = showImage ? `${process.env.REACT_APP_API_URL}/api/PressMessageImage/${id}` : null

    const typeStyle = {
      background: typeColor || '#0A7B7A'
    }

    const dateText = dateTitle ? `${dateTitle} ${moment(date).format('D MMMM YYYY')}` : moment(date).format('D MMMM YYYY')

    const contentClassNames = classNames('contentWrapper', 'col-xs-12', {'col-sm-4': imageUrl, 'col-sm-12': !imageUrl})

    return (
      <div className="pressMessageCard row">
        {
          imageUrl ? <div className="headerImage col-xs-12 col-sm-8"><img src={imageUrl} alt="Header" /></div> : null
        }
        <div className={contentClassNames}>
          <div className="date">{dateText}</div>
          <div className="header">
            <h2>{header}</h2>
          </div>
          <div className="messageTypeWrapper">
            <div className="messageType" style={typeStyle}>{messageType}</div>
          </div>
          <div className="content">
            <RawHTML nl2br={true} content={content || ''}></RawHTML>
          </div>
          <div className="readMore">
            {externalLink ? <a href={externalLink} target="_blank" rel="noreferrer">{lng.PressRoom.ReadArticle}</a> : <Link to={readMoreUrl}>{lng.PressRoom.ReadMore}</Link>}
          </div>
        </div>
      </div>
    )
  }
}

export default PressMessageCard