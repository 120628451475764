import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import noPicture from 'images/nopic.jpg'
import { urlName } from 'lib/utils'
import ArticlePrice from '../ArticlePrice'
import {
  addToCart
} from 'features/ShoppingCart/duck'
import {
  formatDeliveryDate
} from 'lib/utils'
import './style.scss'

class ArticleInfo extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
    lng: PropTypes.object.isRequired,
    productgroups: PropTypes.array.isRequired,
    orderMonthsEN: PropTypes.string,
    orderMonthsSV: PropTypes.string,
    orderMonthsSeasonEN: PropTypes.string,
    orderMonthsSeasonSV: PropTypes.string,
    currentLanguage: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      addQuantities: [],
      articleGroup: {}
    }
  }

  stockStatus = () => {
    let stockstatus
    let suffix = this.props.currentLanguage === 'en' ? 'pcs' : 'st'

    if (this.props.article.QuantityAvailable > 100) {
      return stockstatus = `100  ${suffix}+`
    } else if (this.props.article.QuantityAvailable > 0) {
      stockstatus = `${this.props.article.QuantityAvailable} ${suffix} `
    } else if (this.props.article.QuantityAvailable + this.props.article.OrderedArticles > 0 && this.props.article.EstimatedDeliveryDate) {
      stockstatus = formatDeliveryDate(this.props.article.EstimatedDeliveryDate)
    } else if (this.props.article.QuantityAvailable + this.props.article.OrderedArticles > 0) {
      stockstatus = this.props.lng.Common.Ordered
    } else if (this.props.article.NoArticleStock) {
      if (this.props.currentLanguage === 'en')
        stockstatus = this.props.article.SeasonNews ? this.props.orderMonthsSeasonEN : this.props.orderMonthsEN
      else if (this.props.currentLanguage === 'sv')
        stockstatus = this.props.article.SeasonNews ? this.props.orderMonthsSeasonSV : this.props.orderMonthsSV
    } else {
      stockstatus = this.props.lng.Common.SoldOut
    }
    return stockstatus
  }

  onAddToCart = () => {
    this.props.dispatch(addToCart(this.props.article.Id, this.state.quantity))
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target

    this.setState({
      [name]: Number(value)
    })
  }

  componentWillMount() {
    let addQuantities = []
    for (let i = 1; i <= 10; i++) {
      addQuantities.push(this.props.article.QuantityPerPackage * i)
    }

    let articleGroup = {}
    if (this.props.productgroups) {
      articleGroup = this.props.productgroups.find(group => group.ProductGroupId === this.props.article.ProductGroupId)
    }

    this.setState({
      addQuantities,
      articleGroup,
      quantity: addQuantities[0]
    })
  }

  render() {
    const {
      lng,
      article,
      account
    } = this.props

    const articleGroup = this.state.articleGroup || {}

    const imageUrl = article.ImageId ? `${process.env.REACT_APP_API_URL}/api/ArticleImage/${article.ImageId}` : noPicture
    const fullImageURL = article.ImageId ? `${process.env.REACT_APP_API_URL}/api/ArticleImageFull/${article.ImageId}` : ''
    const articleGroupUrl = `/products/${articleGroup.ProductGroupId}/` + urlName(articleGroup.Name || '')

    return (
      <div className="articleContainer row">
        <div className="imageContainer col-sm-5 col-xs-12">
          <img src={imageUrl} alt="artikel bild" />
          {article.ImageId ? <a className="downLoadImage" href={fullImageURL}>{lng.Product.DownloadHiRes}</a> : ''}
        </div>

        <div className="productInfo col-sm-7 col-xs-12">
          <div className="header">
            <h2>{article.ArticleText} </h2>
            <div className="subTitle">Artikelnummer: {article.ArticleNumber} </div>
          </div>
          <div className="productGroup">
            <label htmlFor="">{lng.Product.ArticleGroup}: </label>
            <Link to={articleGroupUrl}>{articleGroup.Name}</Link>
          </div>
          {
            article.EAN ?
              <div>
                <label htmlFor="">{lng.Product.EANCode}: </label>
                <span>{article.EAN}</span>
              </div> : null
          }
          <div>
            <label htmlFor="">{lng.Product.PackageQuantity}: </label>
            <span>{article.QuantityPerPackage} st.</span>
          </div>
          <div>
            <label htmlFor="">{lng.Product.PackageSize}: </label>
            <span>{article.BoxWidth}x{article.BoxHeight}x{article.BoxDepth}</span>
          </div>
          <div>
            <label htmlFor="">{lng.Product.PackageWeight}: </label>
            <span>{article.Weight} kg</span>
          </div>
          <div>
            <label htmlFor="">{lng.Product.StockStatus}: </label>
            <span>{this.stockStatus()}</span>
          </div>
          <div className="productDescription">
            <label>{lng.Common.Description}</label>
            <p>{article.Description}</p>
          </div>
          {
            account.loggedIn && <ArticlePrice
              article={article}
              lng={lng}
              addQuantities={this.state.addQuantities}
              quantity={this.state.quantity}
              onChange={this.onChange}
              addToCart={this.onAddToCart} />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.user.login
})



export default connect(mapStateToProps)(ArticleInfo)
