import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  validateObject
} from 'lib/validator'

import {
  resetPassword,
  toggleResetPassword
} from './duck'

import validationRules from './validationRules'

import FloatingWindow from 'components/containers/FloatingWindow'
import Button from 'components/common/Button'
import DefaultInput from 'components/common/inputs/DefaultInput'
import LoadingOverlay from 'components/common/LoadingOverlay'
import If from 'components/containers/If'

import './style.scss'

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errors: {},
      validationRules: null
    }
  }

  onSubmit = (e) => {
    e.preventDefault()

    const validation = validateObject(this.state.validationRules, { email: this.state.email })

    this.setState({
      errors: {
        ...validation.properties
      }
    })

    if (validation.valid) {
      this.props.dispatch(resetPassword(this.state.email))
    }
  }

  onChange = (e) => {
    const {
      name,
      value
    } = e.target

    this.setState({
      [name]: value
    })
  }

  onClose = () => {
    this.props.dispatch(toggleResetPassword())
  }

  componentWillMount() {
    const {
      lng
    } = this.props

    this.setState({
      validationRules: validationRules(lng.ErrorTexts)
    })
  }

  render() {
    const {
      lng,
      loading,
      error,
      errorMessage,
      passwordReset
    } = this.props

    const {
      errors,
      email
    } = this.state

    return (
      <FloatingWindow title={lng.ResetPassword.ResetPassword} draggable={false} showHeader={true} closeOutSide={true} onClose={this.onClose}>
        <div className="resetPassword">
          <If condition={passwordReset}>
            <div>
              <div className="success">{lng.ResetPassword.Success}.</div>
              <div>{lng.ResetPassword.SuccessText}.</div>
            </div>
          </If>
          <If condition={!passwordReset}>
            <div>{lng.ResetPassword.ResetPasswordText}.</div>
            {error && errorMessage === '404' ? <div className="error">{lng.ResetPassword.EmailNotFound}</div> : null}
            <form onSubmit={this.onSubmit}>
              <DefaultInput name="email" autocomplete="email" label={lng.Common.Email} type="email" value={email} onChange={this.onChange} error={errors.email} />
              <Button type="submit" text={lng.Common.Reset} />
            </form>
            {loading ? <LoadingOverlay /> : null}
          </If>
        </div>
      </FloatingWindow>
    )
  }
}

const mapStateToProps = (state) => ({
  lng: state.app.language,
  loading: state.user.resetpassword.loading,
  error: state.user.resetpassword.error,
  errorMessage: state.user.resetpassword.errorMessage,
  passwordReset: state.user.resetpassword.passwordReset
})

export default connect(mapStateToProps)(ResetPassword)