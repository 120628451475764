import initialState from './initialState'

const FETCH_MESSAGES = 'md/features/Pressroom/pages/Moomin/MoominMessages/FETCH_MESSAGES'
const FETCH_MESSAGES_SUCCESS = 'md/features/Pressroom/pages/Moomin/MoominMessages/FETCH_MESSAGES_SUCCESS'
const FETCH_MESSAGES_FAILED = 'md/features/Pressroom/pages/Moomin/MoominMessages/FETCH_MESSAGES_FAILED'

export const fetchMessages = (categoryid, laguagecode) => {
  const payload = {
    categoryId: categoryid,
    languageCode: laguagecode
  }
  return {
    types: [FETCH_MESSAGES, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILED],
    callApi: {
      endpoint: 'api/pressroommessages',
      method: 'GET',
      data: payload
    }
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGES:
      return {
        ...state,
        loading: true
      }
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_MESSAGES_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}