import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LoadingSpinner from 'components/common/LoadingSpinner'

import {
  fetchUser,
  logout
} from 'features/User/LoginWidget/duck'
import {
  fetchRequireNewPasswordCheck
} from 'features/User/SetNewPassword/duck'
import {
  toggleMyPage,
} from 'features/App/duck'
import Button from 'components/common/Button'

import CartWidget from 'features/ShoppingCart/CartWidget'
import './style.scss'

export class LoggedIn extends PureComponent {
  logoutUser = () => {
    this.props.dispatch(logout())
  }

  gotoMyPage = () => {
    this.props.dispatch(toggleMyPage(true))
    this.props.history.push('/userprofile')
  }

  componentDidMount() {
    const {
      loading,
      loggedIn,
      user,
      requireNewPassword,
      authUsed
    } = this.props

    if (loggedIn && !loading && user === null) {
      this.props.dispatch(fetchUser())
    }

    if(loggedIn && !authUsed && requireNewPassword === null) {
      this.props.dispatch(fetchRequireNewPasswordCheck())
    }
  }

  render() {
    const {
      lng,
      user
    } = this.props

    if (!user) {
      return <LoadingSpinner />
    }

    return (
      <div className="loggedIn">
        <div className="nameHeader">{lng.Header.LoggedInAs}</div>
        {
          user ? <div>{user.Name}</div> : null
        }
        <CartWidget />
        <div className="buttonWrapper">
          <Button type="button" className="tiny" onClick={this.gotoMyPage} text={lng.MyPage.MyPage} />
          <Button type="button" className="tiny" onClick={this.logoutUser} text={lng.Header.LogOut}></Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.app.language,
  user: state.user.login.user,
  loggedIn: state.user.login.loggedIn,
  loading: state.user.login.loading,
  authUsed: state.user.login.authUsed,
  showmypage: state.app.showMyPage,
  requireNewPassword: state.user.setnewpassword.requireNewPassword
})

export default withRouter(connect(mapStateToProps)(LoggedIn))
